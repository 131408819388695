import React, { Component } from 'react'
import { Header, Container, Grid, Icon } from 'semantic-ui-react'
// import TopBg from '../assets/images/us-gold-header-bg.jpg';
import TopBg from '../assets/images/usgold-header-bg-lg-v03.jpg'
// import WalkingLiberty from '../assets/images/walking-liberty-coin.png';
import USGoldToken from '../assets/images/usg-token.png'
import CubesFiveGold from '../assets/images/cubes-five-gold.png'
import GoldCoinBgWide from '../assets/images/gold-coin-bg-wide-grey.jpg'
import CubesGold02 from '../assets/images/cubes-gold-02.png'
import Flexibility from '../assets/images/flexibility.png'

import IconGoldBacked from '../assets/images/icon-panel-gold-backed.png'

import Footer from './partials/Footer'
import MainLinks from './partials/MainLinks'
import Fade from 'react-reveal/Fade'
// USGInUSD in is master value for entire application
import { USGInUSD } from './partials/USGInUSD'
import {
	HeroHeader,
	HhInnerContainer,
	HhTextContainer,
	HhImageContainer,
	HhH1,
	HhH2,
	FullWidthDiv,
	FullWidthBgDiv,
	FlexRowContainer,
	OpenH3,
	OpenH4,
	OpenP,
	OpenPSmall,
	OpenSpanGreen,
	OpenSpanRed,
	OpenH5,
	BorderedDiv,
	BorderedDivHead,
	BorderedDivBody,
	HoverCenterDiv,
	HoverCenterDivWrap,
	DividerGrayGrad,
	VerticalSpacer,
	HrOrange,
	USGButton,
} from '../css/styledComponents'
import Img from './partials/Img'

const request = require('request')

async function getJSON(url) {
	return new Promise((res, err) => {
		request(url, function (error, response, body) {
			if (!error && response.statusCode === 200) {
				let importedJSON = JSON.parse(body)
				res(importedJSON)
			} else {
				err(error)
			}
		})
	})
}

class About extends Component {
	state = { USGInUSD: 0.0 }

	componentDidMount() {
		let usgPriceUrl =
			window.location.protocol + '//' + window.location.host + '/api/usgprice'
		getJSON(usgPriceUrl).then((usgPriceJson) => {
			console.log(usgPriceJson)
			this.setState({ USGInUSD: usgPriceJson.USG })
		})
		//this.setState({ USGInUSD: 3070.0 })
	}

	render() {
		return (
			<Container fluid>
				<HeroHeader bgImage={TopBg}>
					<Container>
						<HhInnerContainer>
							<HhTextContainer>
								<HhH1>About Us</HhH1>
								<HhH2>Allow us to introduce ourselves.</HhH2>
							</HhTextContainer>
							<Fade>
								<HhImageContainer bgImage={USGoldToken} />
							</Fade>
						</HhInnerContainer>
					</Container>
				</HeroHeader>

				{/*<FullWidthDiv topColor='#fff' bottomColor='#fff' id={1}>*/}
				{/*<Container>*/}
				{/*<OpenH3>*/}
				{/*/!* US Gold Currency is committed to providing a safe, stable-value cryptocurrency with all of the benefits of the best digital currencies, but without the peaks and valleys in value experienced by many cryptocurrencies. *!/*/}
				{/*/!* With over 30 years of experience in monetary gold, US Gold in 2016 expanded into the cryptocurrency sector with the US Gold Currency brand, and created the US Gold blockchain currency token <b>USG</b>. *!/*/}
				{/*Make gold money again*/}
				{/*</OpenH3>*/}
				{/*</Container>*/}
				{/*</FullWidthDiv>*/}

				<FullWidthDiv topColor='#eaebee' bottomColor='#eaebee' id={4}>
					<Container>
						<Grid>
							<Grid.Row>
								<Grid.Column>
									<OpenH3 textAlign='left'>Why USG?</OpenH3>
									<HrOrange />
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column>
									<OpenH4 textAlign='left'>
										We created the USG token to provide an option in the world
										of digital assets that is both stable and backed by a hard
										asset.
									</OpenH4>
									<OpenH4 textAlign='left'>
										Consumers and businesses benefit from a secure digital asset
										token that is not subject to the volatile swings of the
										markets, and from the opportunity to easily exchange their
										digital currency into a tangible asset: monetary gold coins
										produced by the U.S. Mint.
									</OpenH4>

									{/* Today the USG token – designed for the emerging cryptocurrency sector, has addressed the fundamental question: <strong>How do you create a stable-value blockchain currency that’s actually supported by a hard asset currency?</strong> */}
									{/*
                        <OpenP textAlign='left'>
                          A blockchain currency backed by gold was the obvious answer, and US Gold Currency the obvious company to implement it.
                        </OpenP> */}
									{/* <OpenP textAlign='left'>
                          Although an independent company, US Gold Currency relies on decades of operational experience through US Gold – founded in 1980. The entire staff is dedicated to providing our expertise to this global currency opportunity and will remain committed to providing superior customer service and integration of the USG token to the crypto market as a safe, stable, convenient and redeemable alternative to the price volatility often found in emerging markets.
                          Our focus is on creating the support mechanism for global payment systems around the world providing economic benefit to the underserved as well as the investments firms seeking the required stability that only comes from a Hard-Asset blockchain platform.
                        </OpenP> */}
									{/* <OpenP textAlign='left'>
                          We are stewards and students of business trends, technology, and investing.
                        </OpenP> */}

									<OpenH4 textAlign='left'>
										{/* founded in 1980
                          committed to excellent customer service */}
										We have created a safe, stable, and convenient mechanism to
										allow a new set of payment systems which are completely
										scalable and operate everywhere in the world. The USG
										currency stabilizes the financial systems that use it.
									</OpenH4>
									<OpenH4 textAlign='left'>
										Most financial systems, such as Bitcoin, Ethereum, and
										government-issued fiat currencies, are based on trust. In
										contrast, USG currency is 100% backed by monetary gold and
										will always have a 1-to-1 exchange ratio with the American
										Eagle one-ounce gold coins issued by the U.S. Mint.
										{/* **tokenization of real currency**
                          true currency backed by monetary gold
                          us treasury minted gold currency */}
										{/* What backs our currency is real currency. */}
									</OpenH4>
								</Grid.Column>
							</Grid.Row>
						</Grid>
						<Grid stackable divided>
							<Grid.Row columns='equal'>
								<Grid.Column>
									<OpenH5 textAlign='left'>
										USG tokens are a monetary gold coin backed digital asset
										issued and administered via a Smart Contract on the Ethereum
										decentralized blockchain.
									</OpenH5>
								</Grid.Column>
								<Grid.Column>
									<OpenH5 textAlign='left'>
										USG tokens are a direct representation of gold American
										Eagle one ounce coins issued by the United States Mint.
									</OpenH5>
								</Grid.Column>
								<Grid.Column>
									<OpenH5 textAlign='left'>
										USG tokens may be redeemed at any time for the physical gold
										coin.
									</OpenH5>
								</Grid.Column>
								<Grid.Column>
									{/* <OpenH5 textAlign='center'>
                    US Gold Currency LLC is located on the "Crypto Plain" in Wyoming.
                  </OpenH5> */}
									<OpenH5 textAlign='left'>
										The State of Wyoming is the leader in transformational
										legislation providing an unparalleled business landscape to
										blockchain and digital asset infrastructure companies within
										these emerging sectors.
									</OpenH5>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Container>
				</FullWidthDiv>

				<FullWidthBgDiv bgImage={GoldCoinBgWide} height={400}>
					<Container>
						<Grid>
							<Grid.Row>
								<Grid.Column width={1} />
								<Grid.Column width={14}>
									<OpenH3 color='white'>Dependable & Secure</OpenH3>
									<VerticalSpacer height={50} />
									<OpenH4 color='white'>
										The market price for a USG token is always equal to the
										established market price for an American Eagle one ounce
										gold coin from the United States Mint. <br />
										<br />
										From time to time, the value of a USG token may change, and
										in the open market exchanges, the value of USG tokens is set
										by the market condition. <br />
										<br />
										United States Mint current price: $
										{this.state.USGInUSD.toFixed(2)} USD
										<br />
										US Gold Currency current price: $
										{this.state.USGInUSD.toFixed(2)} USD
										{/*United States Mint current price: $3070.00 USD
										<br />
										US Gold Currency current price: $3070.00 USD*/}
									</OpenH4>
								</Grid.Column>
								<Grid.Column width={1} />
							</Grid.Row>
						</Grid>
					</Container>
				</FullWidthBgDiv>

				<FullWidthBgDiv bgImage={Flexibility} id={4}>
					<Container>
						<Grid>
							<Grid.Row>
								{/*<Grid.Column width={16}>*/}
								{/*    <OpenH3 color='white' textAlign='left'>Fantastic Flexibility</OpenH3>*/}
								{/*    <HrOrange/>*/}
								{/*    <Grid padded stackable>*/}
								{/*        <Grid.Row columns='equal'>*/}
								{/*            <Grid.Column>*/}
								{/*                <OpenP color='white' textAlign='left'>*/}
								{/*                    /!* We understand the frustration you feel when you want to exchange or cash out of your cryptocurrency and can't find an easy way to do it. That's why we give you the unique option of exchanging your USG tokens for the physical U.S. Gold Eagle coins by which the tokens are backed. *!/*/}
								{/*                    US Gold Currency gives you the unique option to exchange your USG*/}
								{/*                    tokens for the physical American Eagle one ounce gold coins which*/}
								{/*                    back them.*/}
								{/*                </OpenP>*/}
								{/*            </Grid.Column>*/}
								{/*            <Grid.Column>*/}
								{/*                <OpenP color='white' textAlign='left'>*/}
								{/*                    US Gold Currency is designed to provide you easy and timely access*/}
								{/*                    to the gold asset backing our token should you decide to redeem your*/}
								{/*                    USG tokens instead of trading on one of the many exchanges in the*/}
								{/*                    cryptocurrency sector.*/}
								{/*                </OpenP>*/}
								{/*            </Grid.Column>*/}
								{/*            <Grid.Column>*/}
								{/*                <OpenP color='white' textAlign='left'>*/}
								{/*                    US Gold Currency provides insured shipping, via UPS Parcel Pro. As*/}
								{/*                    you probably know, it's easy to exchange gold for cash — what you do*/}
								{/*                    with your physical American Eagle one ounce gold coins is up to you.*/}
								{/*                </OpenP>*/}
								{/*            </Grid.Column>*/}
								{/*        </Grid.Row>*/}
								{/*    </Grid>*/}
								{/*</Grid.Column>*/}
								<Grid.Column width={16}>
									<OpenH3 color='white' textAlign='left'>
										About Us
									</OpenH3>

									<Grid padded stackable>
										<Grid.Row columns='equal'>
											<Grid.Column>
												<OpenH4 color='white' textAlign='left'>
													Locations &amp; Credentials
												</OpenH4>
												<HrOrange />
												<OpenP color='white' textAlign='left'>
													US Gold Currency, Inc. – (USGold) is headquartered in
													Jackson, Wyoming with offices in the Board of Trade
													Building in Chicago, Illinois. In addition to
													maintaining a A+ Rating from the Better Business
													Bureau (BBB), USGold is the only company issuing Gold
													Digital Assets registered with the United States
													Treasurys' Financial Crimes Enforcement Network
													(FinCEN) as a Money Services Business (MSB) –
													Registration Number 31000148296328.
												</OpenP>
											</Grid.Column>
											<Grid.Column>
												<OpenH4 color='white' textAlign='left'>
													The Token &amp; Benefits
												</OpenH4>
												<HrOrange />
												<OpenP color='white' textAlign='left'>
													USG tokens are a monetary gold coin backed digital
													asset issued and administered via a Smart Contract on
													the Ethereum blockchain. Each USG token is a direct
													representation of a one-ounce American Eagle gold coin
													– issued by the United States Mint – then placed in
													the US Gold Business Trust and held in vault storage
													for the benefit of USG Token holders.
												</OpenP>
												<OpenP color='white' textAlign='left'>
													Additionally, each USG Token may be redeemed at any
													time for the one-ounce physical gold coin that backs
													each USG through a simple online process at:{' '}
													<a
														href='https://www.redeemusg.com'
														alt='RedeemUSG.com'
													>
														<OpenP
															color='white'
															deco='underline'
															textAlign='left'
															paddingBottom='0.5em'
														>
															RedeemUSG.com
														</OpenP>
													</a>
												</OpenP>
											</Grid.Column>
										</Grid.Row>
									</Grid>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Container>
				</FullWidthBgDiv>

				{/*<FullWidthDiv topColor='#eaebee' bottomColor='#eaebee' id={4}>
					<Container>
						<Grid>
							<Grid.Row>
								<Grid.Column width={16}>
									<OpenH3 textAlign='left'>About The Team</OpenH3>
									<OpenH3 textAlign='left'>Principals</OpenH3>

									<Grid padded stackable>
										<Grid.Row columns='equal'>
											<Grid.Column>
												<OpenH4 textAlign='left'>
													Lawrence DeBry, Director US Gold Currency
												</OpenH4>
												<HrOrange />
												<OpenP textAlign='left'>
													Mr. DeBry has an extensive engineering background and
													provided his expertise throughout the Aerospace and
													Commodities Industries, the bulk of which was spent in
													Minerals and Gold. As a senior executive he was
													responsible for billions of dollars in assets across
													the global footprint of companies such as BHPBilliton,
													Rio Tinto, and AngloGold Ashanti. Mr. DeBry has an
													M.B.A. and B.S., in Mechanical Engineering from the
													University of Utah.
												</OpenP>
											</Grid.Column>
											<Grid.Column>
												<OpenH4 textAlign='left'>
													J. Martin Tate, Director Legal and Compliance US Gold
													Currency
												</OpenH4>
												<HrOrange />
												<OpenP textAlign='left'>
													Mr. Tate is legal counsel and Chief of Compliance for
													US Gold Currency. Throughout his career that includes
													Corporate Counsel for Sentry Finance, and Fairbanks
													Capital Corporation, he has provided counsel in
													association with over $500 million in debt and equity
													financings, assisted in the formation of domestic and
													offshore funds in various structures, and has provided
													his counsel to investment managers and advisors on a
													variety of state and SEC compliance issues. Mr. Tate
													is an honors and Morris B. Abrahms Scholar, having
													obtained B.A, Political Science & Spanish, and J.D.
													degrees, from Universities of Utah and Georgia,
													respectively.
												</OpenP>
											</Grid.Column>
										</Grid.Row>
									</Grid>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Container>
				</FullWidthDiv>*/}

				<Footer />
			</Container>
		)
	}
}

const styles = {
	padTop: {
		paddingTop: '30px',
	},
}

export default About
