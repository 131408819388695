import React, { Component } from 'react'
import { Header, Container, Grid, Icon, Image } from 'semantic-ui-react'
import TopBg from '../assets/images/usgold-header-bg-lg-v03.jpg'
import EthereumAddress from '../assets/images/EthereumAddress.jpg'
import USGoldToken from '../assets/images/usg-token.png'

import Footer from './partials/Footer'

import Fade from 'react-reveal/Fade'
// USGInUSD in is master value for entire application
import { USGInUSD } from './partials/USGInUSD'
import {
	HeroHeader,
	HhInnerContainer,
	HhTextContainer,
	HhImageContainer,
	HhH1,
	HhH2,
	FullWidthDiv,
	FullWidthBgDiv,
	FlexRowContainer,
	OpenH3,
	OpenH4,
	OpenP,
	OpenPSmall,
	OpenSpanGreen,
	OpenSpanRed,
	OpenH5,
	BorderedDiv,
	BorderedDivHead,
	BorderedDivBody,
	HoverCenterDiv,
	HoverCenterDivWrap,
	DividerGrayGrad,
	VerticalSpacer,
	HrOrange,
	USGButton,
} from '../css/styledComponents'
import Img from './partials/Img'
import FincenGray from '../assets/images/fincen-gray-400x.png'

const request = require('request')

async function getJSON(url) {
	return new Promise((res, err) => {
		request(url, function (error, response, body) {
			if (!error && response.statusCode === 200) {
				let importedJSON = JSON.parse(body)
				res(importedJSON)
			} else {
				err(error)
			}
		})
	})
}

class EthereumToken extends Component {
	state = { USGInUSD: 0.0 }

	componentDidMount() {
		let usgPriceUrl =
			window.location.protocol + '//' + window.location.host + '/api/usgprice'
		getJSON(usgPriceUrl).then((usgPriceJson) => {
			console.log(usgPriceJson)

			this.setState({ USGInUSD: usgPriceJson.USG })
		})

		//this.setState({ USGInUSD: 3070.0 })
	}

	render() {
		return (
			<Container fluid>
				<HeroHeader bgImage={TopBg}>
					<Container>
						<HhInnerContainer>
							<HhTextContainer>
								<HhH1>Ethereum Address</HhH1>
								<HhH2>0x4000369acfa25c8fe5d17fe3312e30c332bef633</HhH2>
							</HhTextContainer>
							<Fade>
								<HhImageContainer bgImage={USGoldToken} />
							</Fade>
						</HhInnerContainer>
					</Container>
				</HeroHeader>

				<FullWidthDiv topColor='#eaebee' bottomColor='#eaebee' id={4}>
					<Container>
						<Grid>
							{/*<Grid.Row>*/}
							{/*    <Grid.Column>*/}
							{/*        <OpenH3 textAlign='left'>Ethereum Address</OpenH3>*/}
							{/*        /!*<HrOrange/>*!/*/}
							{/*    </Grid.Column>*/}
							{/*</Grid.Row>*/}
							<Grid.Row>
								<Image src={EthereumAddress} centered />
							</Grid.Row>
						</Grid>
					</Container>
				</FullWidthDiv>

				<Footer />
			</Container>
		)
	}
}

const styles = {
	padTop: {
		paddingTop: '30px',
	},
}

export default EthereumToken
