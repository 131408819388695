import React, {Component} from 'react';
import {Header, Container, Grid} from 'semantic-ui-react';
import TopBg from '../assets/images/usgold-header-bg-lg-v03.jpg';
import Footer from './partials/Footer';
import WalletForm from './partials/WalletForm';
import WalletBalance from './partials/WalletBalance';
import WalletControls from './partials/WalletControls';
import WalletTransactions from './partials/WalletTransactions';
import Fade from 'react-reveal/Fade';
import Ledger from '../assets/images/ledger.jpg';
import Wallet from '../assets/images/wallet.jpg';
import Img from './partials/Img';
import USGoldToken from '../assets/images/usg-token.png';
import {
    HeroHeader, HhInnerContainer, HhTextContainer,
    HhImageContainer, HhH1, HhH2,
    FullWidthDiv, FullWidthBgDiv,
    FlexRowContainer,
    OpenH3, OpenH4, OpenP, OpenPSmall, OpenSpanGreen, OpenSpanRed,
    BorderedDiv, BorderedDivHead, BorderedDivBody,
    HoverCenterDiv, HoverCenterDivWrap,
    DividerGrayGrad, VerticalSpacer,
    HrOrange,
    USGButton, Img2, Img3
} from '../css/styledComponents';
import VaultBlue from "../assets/images/vault-color-med.png";

class Wallet2 extends Component {

    render() {


        return (
            <Container fluid>
                <HeroHeader bgImage={TopBg}>
                    <Container>
                        <WalletBalance title='Wallet Options'/>
                    </Container>
                </HeroHeader>
                <FullWidthDiv topColor='#eaebee' bottomColor='#eaebee'>
                    <Container>
                        <div className='ui padded segment'>
                        <div>
                            <OpenH3>Ready for your online Ethereum Wallet? Let’s get you started!</OpenH3>
                            <OpenH3>USGold recommends using Ledger to transfer and hold your USG Tokens.</OpenH3>
                        </div>

                            <div><Img3 margin='0px 20px 20px' spaced src={Wallet} minHeight='500px'/></div>
                            <div>
                                <OpenP><strong>LEDGER</strong> brings Ethereum and USGold to your computing devices
                                    providing safe vault storage and easy access when your ready to transact
                                    USG.</OpenP>
                            </div>
                            <div>
                                <OpenP textAlign='left'>With your Ledger Vault you can safely carry your USG Tokens with
                                    you anywhere you
                                    travel in the world, then when you’re ready to either transfer or receive some USG
                                    simply connect your Ledger Vault to your computer or mobile device, open your online
                                    browser and login into your Ledger account.</OpenP>
                                <OpenP>Please click the Ledger Live icon now.</OpenP>
                                <div className='ui padded margin20' ><a href='https://www.ledger.com'><Img3 margin='20px' spaced src={Ledger}
                                                                                                   minHeight='50px'/></a>
                                </div>

                            </div>

                            <div>
                                <OpenP textAlign='left'>You may check your USG token balance anytime using Etherscan or
                                    Ethplorer by entering
                                    your public Ethereum wallet address that you have used to purchase your USG
                                    Tokens.</OpenP>
                                <OpenP textAlign='left'><em>Please be aware of any incorrect contract addresses listed
                                    under a similar or identical name.</em></OpenP>

                                <OpenP>Correct Token Contract Name: USGold Token (USG)</OpenP>
                                <OpenP>Token Contract: 0x4000369AcfA25C8FE5d17fE3312e30C332beF633</OpenP>
                                <OpenP>
                                    <a href="https://etherscan.io/token/0x4000369acfa25c8fe5d17fe3312e30c332bef633?a">
                                        https://etherscan.io/token/0x4000369acfa25c8fe5d17fe3312e30c332bef633?a
                                    </a>
                                </OpenP>

                            </div>
                        </div>

                    </Container>

                </FullWidthDiv>
                <Footer/>
            </Container>
        );
    }
}

export default Wallet2;
