import React, { Component } from 'react'
import { Header, Container, Grid, Icon } from 'semantic-ui-react'
// import TopBg from '../assets/images/us-gold-header-bg.jpg';
import TopBg from '../assets/images/usgold-header-bg-lg-v03.jpg'
import Stability from '../assets/images/stable.png'

// import WalkingLiberty from '../assets/images/walking-liberty-coin.png';
import USGoldToken from '../assets/images/usg-token.png'
import VaultBlue from '../assets/images/vault-color-med.png'
import Footer from './partials/Footer'
import Fade from 'react-reveal/Fade'
import {
	HeroHeader,
	HhInnerContainer,
	HhTextContainer,
	HhImageContainer,
	HhH1,
	HhH2,
	FullWidthDiv,
	FullWidthBgDiv,
	FlexRowContainer,
	OpenH3,
	OpenH4,
	OpenP,
	OpenPSmall,
	OpenSpanGreen,
	OpenSpanRed,
	BorderedDiv,
	BorderedDivHead,
	BorderedDivBody,
	HoverCenterDiv,
	HoverCenterDivWrap,
	DividerGrayGrad,
	VerticalSpacer,
	HrOrange,
	Img2,
	USGButton,
} from '../css/styledComponents'
import Img from './partials/Img'

class Token extends Component {
	render() {
		return (
			<Container fluid>
				<HeroHeader bgImage={TopBg}>
					<Container>
						<HhInnerContainer>
							<HhTextContainer>
								<HhH1>The USG Token</HhH1>
								<HhH2>As Solid As The Gold That Backs It.</HhH2>
							</HhTextContainer>
							<Fade>
								<HhImageContainer bgImage={USGoldToken} />
							</Fade>
						</HhInnerContainer>
					</Container>
				</HeroHeader>

				<FullWidthDiv topColor='#f9f9f9' bottomColor='#f9f9f9' id={1}>
					<Container>
						<OpenH3>
							US Gold Currency | Kingdom Trust | Delaware Depository
						</OpenH3>
						<OpenH4>
							It's a new blockchain currency which functions as a true digital
							currency — one which provides stable-value and is backed by
							one-ounce American Gold Eagle coins.
						</OpenH4>
						<OpenH4>
							The USG provides a unique set of benefits, including liquidity
							through a simple redemption process.
						</OpenH4>
					</Container>
				</FullWidthDiv>

				<FullWidthDiv topColor='#eaebee' bottomColor='#eaebee' id={4}>
					<Container>
						<Grid>
							<Grid.Row>
								<Grid.Column width={6}>
									<h1>&nbsp;</h1>
									<Fade bottom>
										{/* <Img src={VaultBlue} width={400} height={404} marginTop='-230px' top='230px' /> */}
										{/* <Img2 src={VaultBlue} width='60%' minHeight='350px' marginTop='-230px' top='230px' /> */}
										<Img2 src={VaultBlue} width='60%' minHeight='350px' />
									</Fade>
								</Grid.Column>
								<Grid.Column width={10}>
									<OpenH3 textAlign='center'>REAL | SAFE | TRUSTED</OpenH3>
									<HrOrange />
									<OpenH4 textAlign='left'>
										REAL: Because every USG token is backed by a one-ounce
										American Gold Eagle coin, you know that the value in your
										digital token is tangible and real.
									</OpenH4>
									<OpenH4 textAlign='left'>
										SAFE: For every USG token issued a one-ounce American Gold
										Eagle coin is placed into the USG Gold Trust and deposited
										with the Delaware Depository – a leader in precious metal
										vaulting.
									</OpenH4>
									<OpenH4 textAlign='left'>
										TRUSTED: Kingdom Trust is the Trustee for the USG Gold Trust
										and responsible for the one-ounce American Gold Eagle coins
										that back each USG token.
									</OpenH4>
									<OpenH4 textAlign='left'>
										As a holder of USG tokens, you may elect to redeem your USG
										for the actual one-ounce American Gold Eagle coins through
										Kingdom Trust at anytime by emailing your request to
										<a href={'mailto:RedeemUSG@gmail.com'}>
											RedeemUSG@gmail.com
										</a>
										.
									</OpenH4>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Container>
				</FullWidthDiv>

				<FullWidthBgDiv bgImage={Stability} id={4}>
					<Container>
						<Grid>
							<Grid.Row>
								<Grid.Column width={16}>
									<OpenH3 color='white' textAlign='left'>
										The leading stable-value gold digital currency for
										settlement.
									</OpenH3>
									<HrOrange />
									<Grid stackable>
										<Grid.Row columns='equal'>
											<Grid.Column>
												<OpenP color='white' textAlign='left'>
													Is the value of your Ethereum wallet the same right
													now as it was an hour ago? The odds are, it isn't.
													While the gains are always welcomed, the losses never
													are.
												</OpenP>
											</Grid.Column>
											<Grid.Column>
												<OpenP color='white' textAlign='left'>
													Create some stability with USG tokens, which are
													always tied to the current price set by the U.S. Mint
													for a one-ounce American Gold Eagle coin.
												</OpenP>
											</Grid.Column>
										</Grid.Row>
									</Grid>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Container>
				</FullWidthBgDiv>

				<Footer />
			</Container>
		)
	}
}

const styles = {
	padTop: {
		paddingTop: '30px',
	},
}

export default Token
