import React, { Component } from 'react';
import { Header, Container, Grid } from 'semantic-ui-react';
import axios from 'axios';
// import { setHeaders } from '../../actions/headers';
// import { setFlash } from '../../actions/flash';

import TopBg from '../assets/images/usgold-header-bg-lg-v03.jpg';
import Footer from './partials/Footer';
import SubmitForm from './partials/SubmitForm';
import Fade from 'react-reveal/Fade';
// import WalkingLiberty from '../assets/images/walking-liberty-coin.png';
import USGoldToken from '../assets/images/usg-token.png';
import { withRouter } from 'react-router-dom';
import {
  HeroHeader, HhInnerContainer, HhTextContainer,
  HhImageContainer, HhH1, HhH2,
  FullWidthDiv, FullWidthBgDiv,
  FlexRowContainer,
  OpenH3, OpenH4, OpenP, OpenPSmall, OpenSpanGreen, OpenSpanRed,
  BorderedDiv, BorderedDivHead, BorderedDivBody,
  HoverCenterDiv, HoverCenterDivWrap,
  DividerGrayGrad, VerticalSpacer,
  HrOrange,
  USGButton,
} from '../css/styledComponents';

class Contact extends Component {
  state = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    ethereum_address: '',
    message: ''
  }

  render() {
    return (
      <Container fluid>
        <HeroHeader bgImage={TopBg}>
          <Container>
            <HhInnerContainer>
              <HhTextContainer>
                <HhH1>
                  Contact Us
                </HhH1>
                <HhH2>
                  We'd love to hear from you.
                </HhH2>
              </HhTextContainer>
              <Fade>
                <HhImageContainer bgImage={USGoldToken} />
              </Fade>
            </HhInnerContainer>
          </Container>
        </HeroHeader>
        <FullWidthDiv topColor='#eaebee' bottomColor='#eaebee'>
          <Grid padded>
            <Grid.Row>
              <Grid.Column width={2}></Grid.Column>
              <Grid.Column width={12}>
                <OpenH3>
                  Please send us any questions or comments about our website or products. A US Gold Currency representative will be happy to assist. And, thank you!
                </OpenH3>
              </Grid.Column>
              <Grid.Column width={2}></Grid.Column>
            </Grid.Row>
          </Grid>
          <SubmitForm />
        </FullWidthDiv>
        <Footer />
      </Container>
    );
  }
}

export default Contact;
