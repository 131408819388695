import React, { Component } from 'react'
import axios from 'axios'
import {
	Header,
	Form,
	Button,
	Segment,
	Dropdown,
	Grid,
	Container,
	TextArea,
} from 'semantic-ui-react'
import { FullWidthDiv, HhH1, OpenH3, OpenH2 } from '../../css/styledComponents'
// import { connect } from 'react-redux';
// import { addLead } from '../../actions/leads';
// import { flash } from '../../actions/flash';
// import { setFlash } from '../../actions/flash';
import CubesGold02 from '../../assets/images/cubes-gold-02.png'
import { inlineStyles } from '../../css/inlineStyles.js'
// import { titleOptions, phoneTypeOptions, stateOptions } from './FormOptions.js';
import { titleOptions } from './FormOptions.js'
import { withRouter } from 'react-router-dom'
import Img from './Img'
import { USGInUSD } from './USGInUSD'
import { config } from '../../configs/dev.js'

const request = require('request')
async function getJSON(url) {
	return new Promise((res, err) => {
		request(url, function (error, response, body) {
			if (!error && response.statusCode === 200) {
				let importedJSON = JSON.parse(body)
				res(importedJSON)
			} else {
				err(error)
			}
		})
	})
}

class WalletForm extends Component {
	state = { ethereum_address_to: '', amt_to_Send: '', usd_to_Send: '' }

	handleSubmit = (event) => {
		event.preventDefault()
		const { ethereum_address_to, amt_to_Send, usd_to_Send } = this.state
		const { dispatch, history } = this.props

		// TODO: better error checking

		if (
			!window.confirm(
				'Are you sure you want to send USG? This cannot be undone.'
			)
		) {
			return
		}

		if (false) {
			window.alert('Please complete all fields')
		} else {
			let adjusted_amt = Math.trunc(amt_to_Send * 1000000000)

			window.usg.methods.transfer(ethereum_address_to, adjusted_amt).send()

			console.log('sending...')
			this.setState({
				ethereum_address_to: '',
				amt_to_Send: '',
			})
			window.alert(
				'USG sent, please wait for your balance to reflect this transaction after it is written to the blockchain. '
			)
		}
	}

	handleUSGInputChange = (event) => {
		const id = event.target.id
		const value = event.target.value
		let usd_converted_cost = (this.state.USGInUSD * value).toFixed(3)
		//let usd_converted_cost = (3070 * value).toFixed(3)
		this.setState({ amt_to_Send: value, usd_to_Send: usd_converted_cost })
	}
	handleUSDInputChange = (event) => {
		const id = event.target.id
		const value = event.target.value
		let usg_converted_cost = (value / this.state.USGInUSD).toFixed(9)
		//let usg_converted_cost = (value / 3070).toFixed(9)
		this.setState({ usd_to_Send: value, amt_to_Send: usg_converted_cost })
	}

	handleChange = (event) => {
		// use 'event' to grab the id off the element also the value and set state
		// const { id, value } = event.target;
		const id = event.target.id
		const value = event.target.value
		this.setState({ [id]: value })
	}

	handleOptionChange = (event) => {
		const id = event.target.id
		const value = event.target.value
		// const options = event.target.options;
		this.setState({ [id]: value })
	}

	componentDidMount = () => {
		window.addEventListener('web3Complete', this.setWeb3)
		let usgPriceUrl =
			window.location.protocol + '//' + window.location.host + '/api/usgprice'
		getJSON(usgPriceUrl).then((usgPriceJson) => {
			console.log(usgPriceJson)

			this.setState({ USGInUSD: usgPriceJson.USG })
		})
		//this.setState({ USGInUSD: 3070.0 })
	}
	setWeb3 = () => {
		if (window.needsMetamask) {
			console.log('needs metamask')
		} else {
			this.setState({
				ethereum_address: window.acct,
				usg_balance: window.bal,
			})
		}
	}

	render() {
		const { ethereum_address_to, amt_to_Send, usd_to_Send } = this.state

		return (
			<Form onSubmit={this.handleSubmit}>
				<Grid padded>
					<Grid.Row>
						<Grid.Column>
							<OpenH2 textAlign='left'>Send USG</OpenH2>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Form.Field>
								<input
									id='ethereum_address_to'
									placeholder='Wallet Address to send USG to'
									required
									value={ethereum_address_to}
									onChange={this.handleChange}
								/>
							</Form.Field>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Form.Field>
								<span>USG: </span>
								<input
									id='amt_to_Send'
									placeholder='USG to send'
									required
									value={amt_to_Send}
									onChange={this.handleUSGInputChange}
								/>
							</Form.Field>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Form.Field>
								<span>USD*: </span>
								<input
									id='usd_to_send'
									placeholder='USD to send'
									required
									value={usd_to_Send}
									onChange={this.handleUSDInputChange}
								/>
								<span style={{ fontSize: '8pt' }}>*usd price approximate</span>
							</Form.Field>
						</Grid.Column>
					</Grid.Row>

					<Grid.Row>
						<Grid.Column>
							<Button type='submit'>Submit</Button>
						</Grid.Column>
						<br />
						<span
							style={{ padding: '15px', fontSize: '8pt', fontColor: '#EFEFEF' }}
						>
							NOTE: To minimize network overhead and transaction fees, USG
							utilizes the Ethereum blockchain network, as a result, sending USG
							requires Ethereum to pay transaction fees (about $0.30 per
							transaction), you can{' '}
							<a href='https://www.coinbase.com'>purchase ETH from Coinbase</a>{' '}
							or a place of you choosing. Once you have the Ethereum, send it to
							your USG public wallet address{' '}
						</span>
					</Grid.Row>
				</Grid>
			</Form>
		)
	}
}

export default withRouter(WalletForm)
