import React, {Component} from 'react';
import axios from 'axios';
import {
    Header,
    Form,
    Button,
    Segment,
    Dropdown,
    Grid,
    Container,
    TextArea
} from 'semantic-ui-react';
import {FullWidthDiv, OpenH3, USGButton} from '../../css/styledComponents';
// import { connect } from 'react-redux';
// import { addLead } from '../../actions/leads';
// import { flash } from '../../actions/flash';
// import { setFlash } from '../../actions/flash';
import {inlineStyles} from '../../css/inlineStyles.js';
// import { titleOptions, phoneTypeOptions, stateOptions } from './FormOptions.js';
import {titleOptions} from './FormOptions.js';
import {withRouter} from 'react-router-dom';

class RedeemForm extends Component {
    state = {
        ethereum_address: '',
        qty_to_redeem: '',
        email: '',
        shipping_details: '',
    }

    handleSubmit = event => {
        event.preventDefault();
        const {
            ethereum_address,
            qty_to_redeem,
            shipping_details,
            email
        } = this.state;
        const {dispatch, history} = this.props;



        // TODO: better error checking
        if (false) {
            window.alert('Please complete all fields');
        } else {
            let msg = JSON.stringify(this.state);

            let current_state = this.state;
            current_state.ethereum_address = window.acct;

            //check that they have enough eth before we submit redemption piece
            if(window.usg != null) {
                if (!window.confirm("Are you sure you want to redeem your USG, once you hit OK, this cannot be undone.")) {
                    return;
                }
                window.usg.methods.balanceOf(window.acct).call().then((usg_bal) => {
                    if (usg_bal / 1000000000 >= qty_to_redeem) {
                        window.web3js.eth.getBalance(window.acct).then((bal) => {
                            if (bal > window.web3js.utils.toWei(".001")) {

                                axios.post('/api/redeem', current_state)
                                    .then(function (res) {
                                        //TODO: error handling
                                        console.log("ticket ID:");
                                        console.log(res.data);

                                        window.usg.methods.redeem(qty_to_redeem, window.web3js.utils.keccak256(res.data.ticket_id.toString())).send()
                                            .catch((err) => {
                                                window.alert("Redemption Failed, you need Ethereum (ETH) to pay transaction fees");
                                            });

                                        history.push('/redeemsuccess');
                                    }).catch((err) => {
                                    console.log(err);
                                    window.alert("Appologies for delay, redemption has failed due to server maintinence. Your USG tokens are still in your wallet. Please refresh the page and try again later. If this error persists send an email to support@buyusgold.com. Thank you for your patience -USGold Team");
                                });
                            } else {
                                window.alert("Redemption Failed, you need Ethereum (ETH) to pay transaction fees");
                            }
                        });
                    } else {
                        window.alert("Redemption Failed, you do not have enough USG");
                    }
                }).catch((usg_bal_err) => {
                    window.alert("Redemption Failed, USG balance inaccessible");

                });
            }else{
                axios.post('/api/redeem', current_state)
                    .then(function (res) {
                        //TODO: error handling
                        console.log("ticket ID:");
                        console.log(res.data);

                        history.push('/redeemsuccess');
                    }).catch((err) => {
                    console.log(err);
                    window.alert("Your redemption form submission has failed please try again later. If this error persists send an email to support@buyusgold.com. Thank you for your patience -USGold Team");
                });
            }


        }

        this.setState({
            ethereum_address: '',
            qty_to_redeem: '',
            email: '',
            shipping_details: '',
            name: '',
            phone: '',
            city: '',
            stateProvince: '',
            country: '',
            postalCode: ''
        });
    }

    handleChange = event => {
        // use 'event' to grab the id off the element also the value and set state
        // const { id, value } = event.target;
        const id = event.target.id;
        const value = event.target.value;
        this.setState({[id]: value});
        // console.log(this.state);
    }

    handleOptionChange = event => {
        const id = event.target.id;
        const value = event.target.value;
        this.setState({[id]: value});
    }

    componentDidMount = () => {
        window.addEventListener("web3Complete", this.setWeb3);
    }

    setWeb3 = () => {
        this.setState({ethereum_address: window.acct});
    }

    render() {
        const {
            ethereum_address,
            qty_to_redeem,
            shipping_details,
            email,
            name,
            phone,
            city,
            stateProvince,
            country,
            postalCode
        } = this.state;

        return (
            < FullWidthDiv
        topColor = '#eaebee'
        bottomColor = '#eaebee'
        id = {4}
            >
            < h2 > Redeem
        USG < /h2>
        < Container >
        < Form
        onSubmit = {this.handleSubmit} >
            < Grid
        padded >
        < Grid.Row >
        < Grid.Column
        width = {8} >
            < Form.Field >
            < input
        type = 'text'
        min = '1'
        id = 'name'
        placeholder = 'Name'
        required
        value = {name}
        onChange = {this.handleChange}
        />
        < /Form.Field>
        < /Grid.Column>
        < Grid.Column
        width = {8} >
            < Form.Field >
            < input
        id = 'email'
        placeholder = 'Email Address'
        required
        value = {email}
        onChange = {this.handleChange}
        />
        < /Form.Field>
        < Form.Field >
        < input
        type = 'hidden'
        id = 'ethereum_address'
        placeholder = 'Ethereum Address'
        required
        value = {ethereum_address}
        onChange = {
            this.handleChange
        }
        />
        < /Form.Field>
        < /Grid.Column>
        < /Grid.Row>
        < Grid.Row >
        < Grid.Column
        width = {16} >
            < Form.Field
        id = 'shipping_details'
        placeholder = 'Physical Address: (Street Number / Street Name / Unit Number)

Mailing Address: (Street Number / Street Name / Unit Number or US Post Office Box)
'

        value = {shipping_details}
        control = {TextArea}
        onChange = {this.handleChange}
        />
        < /Grid.Column>
        < /Grid.Row>
        < /Grid>

        < Grid
        padded >
        < Grid.Row >
        < Grid.Column
        width = {8} >
            < Form.Field >
            < input
        type = 'number'
        min = '1'
        id = 'qty_to_redeem'
        placeholder = 'Quantity To Redeem'
        required
        value = {qty_to_redeem}
        onChange = {this.handleChange}
        />
        < /Form.Field>
        < /Grid.Column>
        < Grid.Column
        width = {8} >
            < Form.Field >
            < input
        type = 'text'
        id = 'phone'
        placeholder = 'Phone Number'
        required
        value = {phone}
        onChange = {this.handleChange}
        />
        < /Form.Field>

        < /Grid.Column>

        < /Grid.Row>
        < Grid.Row >
            < Grid.Column
                width = {4} >
                < Form.Field >
                    < input
                        type = 'text'
                        min = '1'
                        id = 'city'
                        placeholder = 'City'
                        required
                        value = {city}
                        onChange = {this.handleChange}
                    />
                    < /Form.Field>
                        < /Grid.Column>

        < Grid.Column
        width = {4} >
            < Form.Field >
            < input
        type = 'text'
        id = 'stateProvince'
        placeholder = 'State / Province'
        required
        value = {stateProvince}
        onChange = {this.handleChange}
        />
        < /Form.Field>
        < /Grid.Column>


        < Grid.Column
        width = {4} >
            < Form.Field >
            < input
        type = 'text'
        id = 'postalCode'
        placeholder = 'Postal Code'
        required
        value = {postalCode}
        onChange = {this.handleChange}
        />
        < /Form.Field>

        < /Grid.Column>

        < Grid.Column
        width = {4} >
            < Form.Field >
            < input
        type = 'text'
        id = 'country'
        placeholder = 'Country'
        required
        value = {country}
        onChange = {this.handleChange}
        />
        < /Form.Field>
        < /Grid.Column>

        < /Grid.Row>

        < /Grid>
        < Segment
        basic
        textAlign = 'center' >
            < Button
        type = 'submit' > Submit < /Button>
            < /Segment>
            < /Form>
            < /Container>
            < /FullWidthDiv>
    )
    }
}

export default withRouter(RedeemForm);
