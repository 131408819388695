import React, { Component } from 'react'
import {
	Container,
	Grid,
	Segment,
	Image,
	Button,
	Responsive,
} from 'semantic-ui-react'
import axios from 'axios'
import TopBg from '../assets/images/usgold-header-bg-lg-v03.jpg'
import Eagle from '../assets/images/Eagle.jpg'
import USGToken from '../assets/images/USGToken.png'
import USGoldToken from '../assets/images/usg-token.png'
import USGoldTokenNoShadow from '../assets/images/usg-token-noWhite.png'
import IconGoldBacked from '../assets/images/icon-panel-gold-backed.png'
import IconStableValue from '../assets/images/icon-panel-stable-value.png'
import IconCoin from '../assets/images/icon-panel-coin.png'
import USGoldWhite from '../assets/images/USGOLDWhite.png'
import BBBGray from '../assets/images/bbb-gray-400x.png'
import FincenGray from '../assets/images/fincen-gray-400x.png'
import WyomingGray from '../assets/images/wyoming-seal-gray-400x.png'
import USGoldEagle from '../assets/images/USGold® – USG – American Gold Eagle Coin.jpg'
import CookieConsent from 'react-cookie-consent'
import Img from './partials/Img'
import Footer from './partials/Footer'
import '../css/react-reveal.css'
import '../css/mailchimp.css'
import {
	HeroHeader,
	HhInnerContainer,
	HhTextContainer,
	HhImageContainer,
	FullWidthDiv,
	OpenH2,
	OpenH3,
	OpenH4,
	OpenP,
	OpenPSmall,
	OpenUl,
	OpenLi,
	BorderedDiv,
	BorderedDivHead,
	BorderedDivBody,
	BorderedDivInnerWrapper,
	HrGrey,
} from '../css/styledComponents'

import Fade from 'react-reveal/Fade'

const request = require('request')
async function getJSON(url) {
	return new Promise((res, err) => {
		request(url, function (error, response, body) {
			if (!error && response.statusCode === 200) {
				let importedJSON = JSON.parse(body)
				res(importedJSON)
			} else {
				err(error)
			}
		})
	})
}

class Home extends Component {
	state = {
		USGInUSD: 0.0,
		ethereumPrice: 0.0,
		ethereum24hrChange: 0.0,
		USGInEthereum: 0.0,
	}

	componentDidMount() {
		//this.getEthereumPrice()
		let usgPriceUrl =
			window.location.protocol + '//' + window.location.host + '/api/usgprice'
		getJSON(usgPriceUrl).then((usgPriceJson) => {
			this.setState({ USGInUSD: usgPriceJson.USG })
			console.log('usgPriceJson: ')
			console.log(usgPriceJson.USG)
		})
		//this.setState({ USGInUSD: 3170.0 })
	}

	render() {
		return (
			<Container fluid>
				<Container>
					<div style={{ position: 'fixed', zIndex: '10' }}>
						<Container className={'cookie-consent-container'}>
							<CookieConsent
								location='top'
								buttonText='Yes, I agree'
								cookieName='myAwesomeCookieName3'
								style={{
									background: 'rgba(0,0,0,.8)',
									position: 'relative !important',
									float: 'right',
									width: '400px',
								}}
								buttonStyle={{
									backgroundColor: 'transparent',
									boxShadow: ' 0 0 0 2px #d4d4d5 inset !important',
									color: '#fff',
									border: '#fff 2px solid',
									borderRadius: '4px',
									marginTop: '0px',
								}}
								expires={150}
							>
								<div>
									We use cookies on this site to enhance your user experience.
									<br />
									<span style={{ fontSize: '10px' }}>
										By clicking any link on this site you give your consent for
										us to use cookies and acknowledge that you have read and
										agree to our{' '}
										<a href='user-agreement#terms-of-use'>Terms Of Use</a>, our{' '}
										<a href='user-agreement'>User Agreement</a>, and our{' '}
										<a href='privacy'>Privacy Policy.</a>
									</span>{' '}
								</div>
							</CookieConsent>
						</Container>
					</div>
				</Container>
				<HeroHeader bgImage={TopBg}>
					<Container>
						<HhInnerContainer>
							<HhTextContainer className={'home-hero-text'}>
								{/*<h1>USGold®</h1>*/}
								<Image src={USGoldWhite} width={210} />

								<div className={'hero-secondary'}>
									<h2>The convenience of digital money.</h2>
									<h2>The security of the blockchain.</h2>
									<h2>The stability of gold.</h2>
								</div>
							</HhTextContainer>
							<Fade>
								<HhImageContainer bgImage={USGoldToken} />
							</Fade>
						</HhInnerContainer>
					</Container>
				</HeroHeader>
				<div className={'hero-spacer'}></div>
				<div className={'hero-wrapper'}>
					<FullWidthDiv style={{ background: '#fff' }}>
						<Container>
							<Grid stackable>
								<Grid.Row className={'top20'}>
									<Grid.Column width={16} style={{ textAlign: 'center' }}>
										<Container>
											<OpenH2>
												USGold is the issuer of the USGold Token — a digital
												asset and direct representation of an American Eagle one
												ounce gold coin — as minted by the United States of
												America.
											</OpenH2>
										</Container>
									</Grid.Column>
								</Grid.Row>

								<Grid.Row className={'top70'}>
									<Grid.Column width={8} style={{ border: '0px' }}>
										<Img
											src={Eagle}
											style={{
												margin: '0 auto 30px',
												width: '150px',
												height: '150px',
											}}
										/>
										<h4
											style={{ textAlign: 'center' }}
											className={'aller uppercase grey-text'}
										>
											ONE USG TOKEN EQUALS ONE AMERICAN EAGLE GOLD COIN
										</h4>
										<p style={{ textAlign: 'center' }}>
											Each USGold token is backed by an American Eagle one ounce
											gold coin, minted by the United States Mint, and may be
											redeemed any time for the physical coin.
										</p>
									</Grid.Column>
									<Grid.Column width={8}>
										<Img
											src={USGToken}
											style={{
												margin: '0 auto 30px',
												width: '150px',
												height: '150px',
											}}
										/>
										<h4
											style={{ textAlign: 'center' }}
											className={'aller uppercase grey-text'}
										>
											USG IS THE DIGITAL CURRENCY WITH STABLE VALUE
										</h4>
										<p style={{ textAlign: 'center' }}>
											USGold is a utility token with a <em>stable-value</em> by
											design. It’s a true digital currency, ideal for global
											payments and a safe haven in volatile markets.
										</p>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row style={{ height: '10%' }}>
									<Grid.Column style={{ textAlign: 'center' }}>
										<OpenH2>
											Vaulted in Trust
											<br />
											Redeemable at anytime
											<br />
											Backed by United States Monetary-Gold Currency
										</OpenH2>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Container>

						<Container>
							<Grid>
								<Grid.Row>
									<Grid.Column width={16} verticalAlign='middle'>
										<a
											target='_blank'
											href='https://trade.coinzoom.com/landing/USG_USD'
										>
											<Img
												src={USGoldTokenNoShadow}
												width={200}
												height={160}
												mode='fit'
												style={{ margin: '0 auto', left: '-20px' }}
											/>

											<OpenH2>Buy USG Now</OpenH2>
											<OpenP>
												<Button textAlign={'center'}>USG TOKEN</Button>
											</OpenP>
										</a>
									</Grid.Column>
								</Grid.Row>

								<Grid.Row columns='equal'>
									<Grid.Column verticalAlign='middle'>
										{/* <FlexRowContainer>
                    <div style={styles.fullWidth}>
                      <OpenPSmall>
                    CURRENT PRICE (IN <strong>ETH</strong>)
                      </OpenPSmall>
                      <OpenPSmall>
                    {this.state.USGInEthereum} &nbsp;
                    {
                    (this.state.ethereum24hrChange > 0)
                    ? <OpenSpanGreen>
                    {this.state.ethereum24hrChange}%
                    </OpenSpanGreen>
                    : <OpenSpanRed>
                    {this.state.ethereum24hrChange}%
                    </OpenSpanRed>
                    }
                      </OpenPSmall>
                    </div>
                  </FlexRowContainer> */}
									</Grid.Column>
									{/*<Grid.Column verticalAlign='middle'>*/}
									{/*  <OpenPSmall>*/}
									{/*    CURRENT SUPPLY<br/>*/}
									{/*  </OpenPSmall>*/}
									{/*  <OpenPSmall>*/}
									{/*    100,937*/}
									{/*  </OpenPSmall>*/}
									{/*</Grid.Column>*/}
									<Grid.Column verticalAlign='middle'>
										<OpenPSmall>
											CURRENT PRICE <br />
										</OpenPSmall>
										<OpenPSmall>${this.state.USGInUSD.toFixed(2)}</OpenPSmall>
										{/*{this.state.USGInUSD && (
											<OpenPSmall>${this.state.USGInUSD.toFixed(2)}</OpenPSmall>
										)}
										{/*<OpenPSmall>3070.00</OpenPSmall>*/}
									</Grid.Column>

									<Grid.Column verticalAlign='middle'>
										<OpenPSmall>
											MARKET SUPPLY <br />
										</OpenPSmall>
										<OpenPSmall>10,000,000</OpenPSmall>
									</Grid.Column>
									<Grid.Column verticalAlign='middle'>
										{/* <OpenPSmall>
                    MARKET VALUE <br/>
                    </OpenPSmall>
                    <OpenPSmall>
                    ${(this.state.USGInUSD * 10000000).toLocaleString(navigator.language, {minimumFractionDigits: 0})}
                  </OpenPSmall> */}
									</Grid.Column>
								</Grid.Row>

								<Grid.Row>
									<Grid.Column>
										<OpenP>
											Redeeming USG tokens is easy: simply click on the Redeem
											Tab at the top of the page and follow the instructions.
											Your American Eagle Gold coins are shipped fully insured
											through United Parcel Service (UPS) –– a government issued
											identification with a matching adult signature is required
											upon delivery.
										</OpenP>
										<OpenPSmall>
											Click for the current price for a 1oz American Eagle Gold
											Coin
											<br />
										</OpenPSmall>
										<OpenP>
											<a
												target='_blank'
												href='https://catalog.usmint.gov/american-eagle-2023-one-ounce-gold-uncirculated-coin-23EH.html?cgid=null&q=American%20Eagle%202023%20One%20Ounce%20Gold%20Uncirculated%20Coin&navid=vizsearch'
											>
												<Button>U.S. MINT</Button>
											</a>
										</OpenP>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Container>
					</FullWidthDiv>
				</div>
				<div className={'hero-wrapper'}>
					<FullWidthDiv className={'hero-sub-row  grey-bg'}>
						<Container>
							<Grid padded stackable>
								<Grid.Row columns='equal' stretched style={styles.greyBorder}>
									<Grid.Column textAlign='center' style={styles.greyBorder}>
										{/* <Fade bottom className='fade-flex'> */}
										<Fade bottom style={{ ...styles.fadeFlex }}>
											<BorderedDiv>
												<BorderedDivInnerWrapper>
													<BorderedDivBody>
														<Img
															src={IconGoldBacked}
															width={120}
															height={77}
															mode='fill'
														/>
													</BorderedDivBody>
													<BorderedDivHead className={'panel-header-text'}>
														Gold Coin-Backed
													</BorderedDivHead>
													<BorderedDivBody>
														<OpenP>
															Each USGold Token is backed by an American Eagle
															one ounce gold coin from the U.S. Mint, creating a
															unique, stable blockchain currency.
														</OpenP>
														<OpenUl>
															<OpenLi>
																Accurate blockchain ledger delivers security and
																transparency.
															</OpenLi>
															<OpenLi>
																Blockchain currency provides instant access and
																convenience.
															</OpenLi>
															<OpenLi>
																Real gold backing maintains unmatched stability.
															</OpenLi>
														</OpenUl>
													</BorderedDivBody>
												</BorderedDivInnerWrapper>
											</BorderedDiv>
										</Fade>
									</Grid.Column>

									<Grid.Column textAlign='center' style={styles.greyBorder}>
										<Fade bottom delay={200}>
											<BorderedDiv>
												<BorderedDivInnerWrapper>
													<BorderedDivBody>
														<Img
															src={IconStableValue}
															width={120}
															height={77}
															mode='fill'
														/>
													</BorderedDivBody>
													<BorderedDivHead className={'panel-header-text'}>
														Stable Value Token
													</BorderedDivHead>
													<BorderedDivBody>
														<div>
															<OpenP>
																Each USGold Token is tied to an American Eagle
																one ounce gold coin, creating a unique, stable
																currency measured in gold — not dollars.
															</OpenP>
															<OpenUl>
																<OpenLi>
																	Our tokens are equally matched to the United
																	States Mint published price.
																</OpenLi>
																<OpenLi>
																	Our tokens are a perfect overnight safe-haven
																	for cryptocurrency day traders.
																</OpenLi>
																<OpenLi>
																	Our tokens are the safe alternative to
																	volatile market conditions.
																</OpenLi>
															</OpenUl>
														</div>
													</BorderedDivBody>
												</BorderedDivInnerWrapper>
											</BorderedDiv>
										</Fade>
									</Grid.Column>
									<Grid.Column textAlign='center' style={styles.greyBorder}>
										<Fade bottom delay={400}>
											<BorderedDiv>
												<BorderedDivInnerWrapper>
													<BorderedDivBody>
														<Img
															src={IconCoin}
															width={88}
															height={77}
															mode='fill'
														/>
													</BorderedDivBody>
													<BorderedDivHead className={'panel-header-text'}>
														Perfect Currency
													</BorderedDivHead>
													<BorderedDivBody>
														<div>
															<OpenP>
																{/* USG Tokens may be redeemed any time for the physical American Eagle gold coins that they directly represent. */}
																You may redeem your USGold Tokens at any time
																for the physical American Eagle one ounce gold
																coins that they directly represent.
															</OpenP>
															<OpenUl>
																<OpenLi>
																	American Eagle gold coins are real currency.
																</OpenLi>
																<OpenLi>
																	USGold Currency tokens are also real currency.
																</OpenLi>
																<OpenLi>
																	Stable value tokens backed by gold: the ideal
																	blockchain currency for everyday use.
																</OpenLi>
															</OpenUl>
														</div>
													</BorderedDivBody>
												</BorderedDivInnerWrapper>
											</BorderedDiv>
										</Fade>
									</Grid.Column>
								</Grid.Row>
							</Grid>
							{/* <VerticalSpacer height='50px' /> */}
						</Container>
					</FullWidthDiv>
				</div>
				<FullWidthDiv className={'security-is-paramount'} id={4}>
					<Container>
						<Grid stackable>
							<Grid.Row>
								<Grid.Column width={16}>
									<OpenH3 textAlign='center'>Security is Paramount</OpenH3>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={2} />
								<Grid.Column width={6}>
									<h4 style={{ textAlign: 'center' }}>Delaware Depository</h4>
									<p style={{ textAlign: 'center' }}>
										Every American Eagle one ounce gold coin backing a USG token
										is stored in a fully insured, secure vault, and audited
										daily in real time by the Delaware Depository.
									</p>
								</Grid.Column>
								<Grid.Column width={6}>
									<h4 style={{ textAlign: 'center' }}>Ethereum Blockchain</h4>
									<p style={{ textAlign: 'center' }}>
										USG tokens are issued via a smart contract on the Ethereum
										blockchain platform. Providing immutable transparent
										transactions for safety and accuracy.
									</p>
								</Grid.Column>
								<Grid.Column width={2} />
							</Grid.Row>
							<Grid.Row>
								<Grid.Column>
									<p style={{ marginTop: '3.5vh', textAlign: 'center' }}>
										Come experience the feeling of knowing your currency is
										backed by real gold.
									</p>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Container>
				</FullWidthDiv>

				<FullWidthDiv
					topColor='#fff'
					bottomColor='#fff'
					className={'solid-gold-solid-company'}
					id={6}
				>
					<Container>
						<Grid stackable>
							<Grid.Row>
								<Grid.Column width={16}>
									<OpenH3>United States Monetary Gold</OpenH3>
									<HrGrey />
									<Fade>
										<Image className={'gold-bars'} src={USGoldEagle} fluid />
									</Fade>
									<OpenH4>
										Although we live in a rapidly changing world, one thing has
										never changed: the use of Gold as a reliable store of value.
										<br />
										<br />
									</OpenH4>
									<OpenP>
										USGold Currency adds something to this already-solid
										mechanism: The market price for a USG token on this website
										is always equal to the established market price for an
										American Eagle one ounce gold coin from the United States
										Mint.
									</OpenP>
									<OpenP>
										From time to time, the value of a USG token may change, and
										in the open market exchanges, the value of USG tokens is set
										by the market condition.
									</OpenP>
									<OpenH4>
										United States Mint current price: $
										{this.state.USGInUSD.toFixed(2)} USD
										<br />
										USGold Currency current price: $
										{this.state.USGInUSD.toFixed(2)} USD
										{/*United States Mint current price: $3070.00 USD
										<br />
										USGold Currency current price: $3070.00 USD*/}
									</OpenH4>
								</Grid.Column>
							</Grid.Row>
						</Grid>

						<Grid stackable>
							<Grid.Row columns='equal'>
								<Grid.Column>
									<FullWidthDiv
										topColor='#ccc'
										bottomColor='#eee'
										style={{ marginTop: '9px' }}
										padTopAndBottom='20px'
										padLeftAndRight='10%'
										height='350px'
									>
										<OpenP>
											<Image src={FincenGray} centered size='tiny' />
											<br />
											<br />
											<strong>Registration:</strong>
											<br />
											United States Treasury FinCEN – Financial Crimes
											Enforcement Network.
											<br />
											MSB Registration Number: 31000148296328
										</OpenP>
									</FullWidthDiv>
								</Grid.Column>
								<Grid.Column>
									<FullWidthDiv
										topColor='#ccc'
										bottomColor='#eee'
										style={{ marginTop: '9px' }}
										padTopAndBottom='20px'
										padLeftAndRight='10%'
										height='350px'
									>
										<OpenP>
											<Image src={WyomingGray} centered size='tiny' />
											<br />
											<br />
											<strong>Licensing:</strong>
											<br />
											State of Wyoming Money Transmitter <br />
											Exempt per W.S.
											<br /> 40-22-104 (a) (vii)
											<br />
											Bond # 100392560
										</OpenP>
									</FullWidthDiv>
								</Grid.Column>
								<Grid.Column>
									<FullWidthDiv
										topColor='#ccc'
										bottomColor='#eee'
										style={{ marginTop: '9px' }}
										padTopAndBottom='20px'
										padLeftAndRight='10%'
										height='350px'
									>
										<OpenP>
											<Image src={BBBGray} centered size='tiny' />
											<br />
											<br />
											<strong>Better Business Bureau:</strong>
											<br />
											BBB Rating A+
											<br />
											Number: 1000105176
										</OpenP>
									</FullWidthDiv>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Container>
				</FullWidthDiv>

				<Footer />
			</Container>
		)
	}
}

const styles = {
	image50tall: {
		width: 'auto',
		height: '50px',
	},
	noPadOrMargin: {
		padding: 0,
		margin: 0,
	},
	goldBorder: {
		border: '1px',
		borderStyle: 'solid',
		borderColor: '#f8da1d',
	},
	pushedDown: {
		position: 'relative',
		transform: 'translateY(300px)',
		paddingBottom: '300px',
	},
	padTop: {
		paddingTop: '30px',
	},
	padTopB: {
		paddingTop: '70px',
	},
	fullWidth: {
		width: '100%',
	},
	orangeBorder: {
		borderColor: 'orange',
		borderWidth: '0',
		borderStyle: 'solid',
	},
	greyBorder: {
		borderColor: '#bababa',
		borderWidth: '0',
		borderStyle: 'solid',
	},
	fadeFlex: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
}

export default Home
