import React, {Component} from 'react';
import {Container, Grid, Segment, Image} from 'semantic-ui-react';
import axios from 'axios';
import {USGInUSD} from './partials/USGInUSD';

// ---------- images ------------
import TopBg from '../assets/images/usgold-header-bg-lg-v03.jpg';
import BricksGold from '../assets/images/bricks-gold.png';
import GoldBars from '../assets/images/gold-bars.jpg';
import InfinityBlue from '../assets/images/infinity-blue.png';
import USGoldToken from '../assets/images/usg-token.png';
import PoweredByOpenNetwork from '../assets/images/powered-by-open-network.png';
import IconGoldBacked from '../assets/images/icon-panel-gold-backed.png';
import IconStableValue from '../assets/images/icon-panel-stable-value.png';
import IconPerfectExit from '../assets/images/icon-panel-perfect-exit.png';
import MainPanelsBg from '../assets/images/main-panels-bg.jpg';
import CubesColored from '../assets/images/cubes-colored.png';
import LinesColored from '../assets/images/lines-three-colored.png';
import CubeGold from '../assets/images/cube-gold.png';
import ChartBitcoin from '../assets/images/chart-bitcoin.jpg';
import ChartEthereum from '../assets/images/chart-ethereum.jpg';
import ChartUSGold from '../assets/images/chart-usgold.jpg';
import GoldOnGradient from '../assets/images/gold-bar-on-gradient.png';
import CubeColoredB from '../assets/images/cube-colored-b.png';
import BuyKeepRedeem from '../assets/images/buy-keep-redeem-bg-wide.jpg';
import PaymentsAccepted from '../assets/images/payments-accepted.png';

// ---------- docs -----------------

import PurchaseAgreementDownload from '../assets/Docs/TermsofPurchasev3.pdf';

import UserAgreementDownload from '../assets/Docs/USGoldCurrencyLLC-UserAgreementDec12019.pdf';
// ---------- partials -------------
import Img from './partials/Img';
import Footer from './partials/Footer';

// ---------- styling ---------------
import '../css/mailchimp.css';
import {
    HeroHeader, HhInnerContainer, HhTextContainer,
    HhImageContainer, HhH1, HhH2,
    FullWidthDiv, FullWidthBgDiv,
    FlexRowContainer,
    OpenH3, OpenH4, OpenP, OpenPSmall, OpenSpanGreen, OpenSpanRed,
    OpenUl, OpenLi, OpenH5, OpenOl,
    BorderedDiv, BorderedDivHead, BorderedDivBody,
    HoverCenterDiv, HoverCenterDivWrap,
    DividerGrayGrad,
    HrOrange,
    USGButton,
} from '../css/styledComponents';

// https://www.react-reveal.com/examples/
import Fade from 'react-reveal/Fade';
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
    AccordionContainer,
} from 'react-accessible-accordion';


const Content = () => (
    <Accordion>
        <AccordionItem>
            <AccordionItemTitle>
                1. BUYING FROM US GOLD CURRENCY
            </AccordionItemTitle>
            <AccordionItemBody>
                <OpenP textAlign='left'>
                    All sales are subject to the Terms of Purchase set forth on Exhibit A and the Trust Agreement set
                    forth on Exhibit B. User agrees to the terms of the Terms of Purchase and Trust Agreement in
                    connection with the purchase of the USG tokens.
                </OpenP>
                <Accordion>
                    <AccordionItem>
                        <AccordionItemTitle>
                            A. MINIMUM SALES ORDERS
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                There is a minimum Sales Order for the USGold token, regardless of the payment method,
                                (BTC / ETH / USD) which is one (1) USG token for a minimum purchase price set forth on
                                the Website as determined by the United States Mint <a
                                href="https://catalog.usmint.gov/american-eagle-2023-one-ounce-gold-uncirculated-coin-23EH.html?cgid=null&q=American%20Eagle%202023%20One%20Ounce%20Gold%20Uncirculated%20Coin&navid=vizsearch"
                                target="_blank">here</a>.
                            </OpenP>

                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            B. ANTI-MONEY LAUNDERING
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                All purchases and redemptions of the USGold Tokens are subject to the USGC AML policies
                                and procedures and the completion of the information set forth on Schedule I, and
                                clearance of certain anti-money laundering requirements prior to purchase and
                                redemption. USGC retains the right to refuse the sale or redemption of USG to any person
                                not providing such information or otherwise failing to comply with USGC AML policies and
                                procedures, which can be found at https://usgold.io/user-agreement . By agreeing to
                                Terms of use and consummating a transaction with USGC, each person represents and
                                warrants to USGC that (i) the purchaser is in compliance with the USA Patriot Act, (ii)
                                none of the funds being used to consummate the transaction were derived from or related
                                or connected to money-laundering, terrorism or any other illegal or illicit activity and
                                (iii) the transaction is not a scheme, or part of a scheme, involving or in support of
                                terrorism, money-laundering or any other illegal or illicit activity. USGC, at all
                                times, reserves the right to require the purchasers or holders to provide additional
                                supporting information and documentation in support of the preceding representations and
                                warranties or to ensure compliance with FinCEN regulations.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            C. SALES ORDER PRICE CONFIRMATIONS
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                When buying USGold Tokens through the Website, directly from USGC, or through an
                                authorized exchange, the price at which your USG token Sales Order is submitted is the
                                locked-in price set forth on the Website or exchange. There are no added charges or
                                commissions, and no additional shipping as your USGold token(s) will be sent
                                electronically to your approved digital wallet address.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            D. CANCELLATIONS OF SALES ORDERS WITH USGC
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                You have no right to cancel your USGold Token Sales Order(s) (Sales Order(s)) after the
                                purchase order is placed. However, if you default in your performance of payment of the
                                purchase price, shipping and handling fees or any other act required, we may cancel your
                                Sales Order(s). We reserve the right to cancel any Sales Order at any time and for any
                                reason.
                            </OpenP>
                            <OpenP textAlign='left'>
                                Any USGold Token subject to a Sales Order cancellation shall vest in and remain the
                                property of USGC. At any time USGC may elect to charge a $250.00 cancellation fee and
                                upon receipt of such cancellation payment, we will waive further action against you and
                                your responsibility for any breach by you and all resulting damages owed to USGC.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            E. SALES ORDER CHANGES
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                Because a USGold Token Sales Order may not be changed, we suggest you take the time up
                                front to make sure what you want is exactly what you request from us as reflected in
                                your Sales Order.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            F. SALES ORDER PAYMENT REQUIREMENTS
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                When you buy from us, you must ensure that your payment is received by USGC. If you
                                default and payment is not timely received by USGC, USGC reserves the right in its sole
                                discretion to (a) accept your payment; (b) refuse your payment and cancel your USGold
                                Token Sales Order; (c) close your account; and/or (d) preclude you from placing any
                                future USGold Token Sales Orders from USGC.
                            </OpenP>
                            <OpenP textAlign='left'>
                                It is always best to send us your payment immediately to complete your transaction with
                                USGC and avoid any possible default so you can realize the locked-in price as of your
                                confirmation time. When making payment by Bank Wire Transfer, we recommend making
                                payment the same day your USGold Token Sales Order is placed.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            G. SENDING YOUR PAYMENT
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                Payment instructions will be issued online on the final page of the online sales order
                                process and again by email after you place your USGold Token Sales Order. Payment must
                                be received within 24 hours of your Sales Order date. If you default and you do not meet
                                these requirements, USGC reserves the right to cancel your USGold Token Sales Order. We
                                reserve the right to refuse or cancel any USGold Token Sales Order at any time,
                                including if it is deemed questionable or of significant risk to USGC regardless of
                                payment method and price confirmation and in such case, you waive any right to any claim
                                or actual damages of any kind or nature whatsoever.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            H. PAYMENT OPTIONS
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                At our sole discretion and if/when available, depending upon the Sales Order amount, you
                                may pay by some or all of the following payment methods: Bitcoin, Ethereum, cash and
                                bank wire transfer in USD. In some special circumstances, you may be allowed to pay via
                                credit card or using your PayPal account, in the event you are permitted to use PayPal
                                your USG token Sales Order must be paid using a VISA Debit card only and the transaction
                                may require additional information including and not limited to CVV code, Zip code
                                associated with the address for the VISA Debit card bank account and the PIN associated
                                with the VISA Debit card bank account.
                            </OpenP>
                            <OpenP textAlign='left'>
                                USG token Sales Orders using a VISA Debit card for any amount(s) are reviewed on an
                                order by order basis. In the sole discretion of USGC, your USGold Token Sale Order –
                                Debit card transaction, may be subject to additional documentation or screening and are
                                subject to our pre-approval. USGC has the right to refuse any Debit Card payment at its
                                sole discretion.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    {/*<AccordionItem>*/}
                    {/*    <AccordionItemTitle>*/}
                    {/*        I. WIRING FUNDS- PAYMENT IN CRYPTOCURRENCY*/}
                    {/*    </AccordionItemTitle>*/}
                    {/*    <AccordionItemBody>*/}
                    {/*        <OpenP textAlign='left'>*/}
                    {/*            Once you have placed a Sales Order for USGold Tokens and we have issued a confirmation*/}
                    {/*            number, we will provide you wire instructions, including our bank name, ABA routing*/}
                    {/*            number and account number. If you are purchasing via Bitcoin, Ethereum or other*/}
                    {/*            cryptocurrency, then transfer must be made to USGC in accordance with the instructions*/}
                    {/*            provided on the Website or the applicable exchange.*/}
                    {/*        </OpenP>*/}
                    {/*    </AccordionItemBody>*/}
                    {/*</AccordionItem>*/}
                    <AccordionItem>
                        <AccordionItemTitle>
                            I. GOOD FUNDS POLICY
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                Receipt of good funds is the day we receive your payment. Additional delays may occur if
                                your bank does not cooperate in assisting us with the information needed to verify your
                                transaction.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            J. CREDIT CARDS
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                We may accept, but are not obligated to accept, credit card payments for Sales Order(s).
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            K. ACH OR ONLINE PAYMENTS FOR SALES ORDERS
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                We do not accept ACH transactions for Sales Orders of USG.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                </Accordion>
            </AccordionItemBody>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemTitle>
                2. US GOLD COINS
            </AccordionItemTitle>
            <AccordionItemBody>
                <Accordion>
                    <AccordionItem>
                        <AccordionItemTitle>
                            A. OWNERSHIP
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                Each USGold Token represents ownership of one American Eagle one ounce gold coin (“AE
                                Coin”), which coin will be owned by the US Gold Trust (the “Trust”), Prime Trust
                                trustee, for the benefit of the Token Holders, as evidenced by the corresponding hash
                                and recorded on the Ethereum blockchain. The AE Coin will be stored in a secure facility
                                maintained by the Custodian as described in the Trust Agreement. The AE Coins will be
                                owned by the Trust pursuant to the Trust Agreement attached hereto as Exhibit B, which
                                any purchaser or holder of the USGold Token(s) hereby acknowledges and agrees to be
                                bound to the terms thereof.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            B. LOSS OF HASH OR PRIVATE KEY
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                Any loss of the hash or the private key in which the USGold Tokens(s) is stored will
                                result in a loss of the USGold Token and an escheat of the AE Coin.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                </Accordion>
            </AccordionItemBody>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemTitle>
                3. REDEMPTION
            </AccordionItemTitle>
            <AccordionItemBody>
                <Accordion>
                    <AccordionItem>
                        <AccordionItemTitle>
                            A. REDEMPTION OF USGOLD TOKENS FOR GOLD COINS
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                Holders of a USGold Token may redeem their USGold Token at any time for the actual AE
                                Coin owned by the Trust for their benefit. In order to redeem the AE Coin, holders may
                                redeem their USGC through the Website by transferring the USGold Token to USGC or by
                                contacting USGC by email at support@buyusgold.com or calling +1 800 673 5800. Upon
                                transfer of the USGold Token to USGC, that token will be terminated and an AE Coin will
                                be delivered to the holder. The holder redeeming the USGold Token shall be responsible
                                for shipping, handling and insurance upon any redemption of a USGold Token for the
                                physical American Eagle one ounce gold coin(s).
                            </OpenP>

                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            B. SHIPPING GOLD AMERICAN EAGLE COINS
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                Upon exchange of a USGold Token for an American Eagle one ounce gold coin (“AE Coin”),
                                such coin(s) shall be shipped by USGC via UPS Parcel Pro, or another common carrier.
                                Each package shall be fully insured. Packages sent registered and insured delivery can
                                take on average 5-10 Business Days to receive.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            C. ESTIMATED U.S. SHIPPING, HANDLING AND INSURANCE CHARGES
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                1. One (1) American Eagle one ounce gold coin (AE Coin)<br/>
                                2. Minimum $30.00 (USD) inside the USA<br/>
                                3. Insured Shipping through UPS Parcel Pro – Adult Signature is required.<br/>
                            </OpenP>
                            <OpenP textAlign='left'>
                                USGC insures all of its coin shipments for the value of the enclosed American Eagle one
                                ounce gold coin(s) redeemed from USGC, subject to the following limitations: USGC must
                                be notified of a lost package within thirty (30) days from the ship date of your
                                redemption order. Should anything happen while a package is in transit to, the holder
                                may be permitted to claim coverage under our insurance policy provided that you directly
                                receive the package from UPS Parcel Pro or another common carrier. Neither USGC, nor the
                                applicable insurance company, accepts (and USGC does specifically disclaim)
                                responsibility if a redeeming holder has provided incorrect instructions, including an
                                incorrect address, or instructions with any carrier or delivery service to leave parcels
                                without signature requirements. Furthermore, USGC does not accept (and we specifically
                                disclaim) responsibility for loss of the AE Coin if the redeeming holders have provided
                                instructions to leave the package with a third party, such as a building manager,
                                neighbor, or a drop-off location such as Mail Boxes Etc., The UPS Store, or similar
                                third party drop off locations. In any such case, packages will not be covered for
                                insurance by USGC or our insurance carrier. If you do not receive the package directly
                                from the common carrier, you agree to take all reasonable actions to assist in recovery
                                of the package, including but not limited to filling-out reports (and/or police reports)
                                and providing all information needed or that may be requested to assist in recovery of
                                the package. If you do not fully cooperate in recovery efforts, neither USGC, nor our
                                insurance company or carrier, will have any liability to you.
                            </OpenP>
                            <OpenP textAlign='left'>
                                In the event of a lost package, USGC will file a claim. Once the claim is filed, we
                                reserve the right to re-ship your items or send the equivalent value in USD at our
                                discretion, in which case USGC shall have no further responsibility or liability to you.
                                If there is any disruption in the delivery of a package to you, you agree to cooperate
                                with us in any investigation or claim process and to take every reasonable action
                                requested of you in the process. Failure to fully cooperate will jeopardize coverage
                                that may be provided to you.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemTitle>
                            D. INTERNATIONAL DUTIES AND TAXES
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                International purchasers shall be responsible for any applicable taxes or duties
                                associated with the purchase of the USG or the receipt of an AE Coin. Please consult
                                your local customs broker for more information on duty and tax rates.
                            </OpenP>
                            <OpenP textAlign='left'>
                                All shipping amounts listed below are estimates. Please contact USGC us for the most
                                current shipping charges.
                            </OpenP>
                            <OpenP textAlign='left'>
                                Your coins are shipped by us, in our discretion, by UPS Parcel Pro, or another common
                                carrier. Each package is fully insured. Packages sent registered and insured delivery
                                can take on average 5-10 Business Days to receive.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            E. ESTIMATED INTERNATIONAL SHIPPING, HANDLING AND INSURANCE CHARGES
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                1. One (1) American Eagle one ounce gold coin<br/>
                                2. Minimum $50.00 (USD) outside the USA – plus any Duty charges with Customs<br/>
                                3. Insured Shipping through UPS Parcel Pro – Adult Signature is required.
                            </OpenP>
                            <OpenP textAlign='left'>
                                USGC always declares the full value of the shipment on any customs form. There are no
                                exceptions to this rule.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            F. INTERNATIONAL DELIVERY REQUIREMENTS
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                If your AE Coin(s) is returned to USGC due to your refusal to pay duties or taxes, or if
                                the carrier (for example, UPS or Federal Express) was unable to deliver your AE Coins,
                                you agree you are responsible for any return shipping fees, additional duties, or
                                applicable taxes for returning the package back to USGC. In addition, if your package is
                                returned to USGC, we will not reship your AE Coin unless and until we are paid in full
                                for any return shipping costs, duties, taxes we have had to pay or advance for you as
                                well as any subsequent re-ship fees back to you.
                            </OpenP>
                            <OpenP textAlign='left'>
                                International returns on AE Coin are very costly and we strongly suggest making yourself
                                aware of any duties or taxes before packages are shipped to avoid surprises. In
                                addition, we strongly suggest making yourself available to the delivery company so your
                                package is not returned to USGC, subjecting you to unnecessary taxes, duties, shipping
                                costs or delays. Delays for Sales Order re-ships could take 6-8 weeks or longer to
                                complete when packages are not received as they should be. If you have any questions
                                about your international shipment, please send an inquiry to <a
                                href="mailto: support@buyusgold.com">support@buyusgold.com</a> or call +1 800 673 5800
                                inside the USA or outside the USA +1 307 634 0777.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            G. RETURN POLICY
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                Once received, the AE Coins are non-returnable.
                            </OpenP>
                            <OpenP textAlign='left'> <strong>Shipping and handling charges are
                                non-refundable.</strong> For approved exchanges, you must follow all instructions
                                provided on the Website, including carefully packaging the Product. You are responsible
                                for the cost of return shipping, insurance and the risk of loss during the return
                                shipment as part of the exchange. USGC reserves the right, at its sole discretion, to
                                reject any exchange that does not comply with these requirements. If an exchange is
                                requested, we reserve the right to accept or refuse the exchange request in our sole
                                discretion, any market gain on returns shall vest in and remain the property of USGC.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                </Accordion>
            </AccordionItemBody>
        </AccordionItem>
        <AccordionItem>
            <AccordionItemTitle>
                4. RISKS
            </AccordionItemTitle>
            <AccordionItemBody>
                <Accordion>
                    <AccordionItem>
                        <AccordionItemTitle>
                            A. BASIS FOR PURCHASE.
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                Purchasing USGold Tokens is at purchaser’s sole risk and that the USGold Tokens are each
                                provided, used and acquired on an “AS IS” and on an “AS AVAILABLE” basis without
                                representations, warranties, promises or guarantees whatsoever of any kind by Company.
                                purchaser must rely on its own examination and investigation thereof.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            B. NO OTHER RIGHTS, FUNCTIONALITY OR FEATURES.
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                USGold Tokens have no rights, uses, purpose, attributes, functionalities or features,
                                express or implied, except as set forth on the Website.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            C. PURCHASE PRICE RISK.
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                There are no guarantees as to the price of USGold Tokens purchased by purchaser and no
                                guarantees that the price per USGold Token determined for each period by the market will
                                be equal to or higher than the price paid by purchaser. There is the possibility that
                                the price per USG token will fall below the price paid by initial purchasers of USG
                                tokens during the Distribution Period. Company reserves the right to change the duration
                                of the Distribution Period for any reason, including, without limitation, bugs in the
                                distribution contract or the unavailability of the Website or other unforeseen
                                procedural or security issues.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemTitle>
                            D. BLOCKCHAIN DELAY RISK.
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                On the Ethereum blockchain, timing of block production is determined by proof of work so
                                block production can occur at random times. For example, USG token transfers in a given
                                open ledger may not be included in the next last-closed ledger and may be included in
                                the candidate set for the beginning of the consensus process on the next open ledger. As
                                a result, the last-closed ledger may not include purchaser’s transaction at the time
                                purchaser expects and purchaser may not receive USG tokens on the same day purchaser
                                purchases the USG tokens. Buyer acknowledges and understands that the Ethereum
                                blockchain may not include the purchaser’s transaction at the time purchaser expects and
                                purchaser may not receive USG tokens the same day purchaser sends ETH.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            E. ETHEREUM BLOCKCHAIN.
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                The Ethereum blockchain is prone to periodic congestion during which transactions can be
                                delayed or lost. Individuals may also intentionally spam the Ethereum network in an
                                attempt to gain an advantage in purchasing cryptographic tokens. purchaser acknowledges
                                and understands that Ethereum block producers may not include purchaser’s transaction
                                when purchaser wants or purchaser’s transaction may not be included at all.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            F. THIRD PARTY ATTACKS.
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                Transactions within the USGC platform may be delayed or lost due to operational error or
                                malicious attacks by third parties. purchaser acknowledges and understands that the
                                last-closed ledger may not include purchaser’s transaction when purchaser wants or
                                expects and that purchaser’s transaction may be excluded or discarded entirely.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            G. OPERATIONAL ERROR.
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                Buyer may never receive USGold Tokens and may lose the entire amount purchaser paid to
                                Company for such USGold Tokens as a result of interruptions and operational errors in
                                the process of purchasing or receiving the USGold Tokens.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            H. ABILITY TO TRANSACT OR RESELL.
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                Buyer may be unable to sell or otherwise transact in USGold Tokens at any time, or for
                                the price purchaser paid. By purchasing USGold Tokens, purchaser acknowledges,
                                understands and agrees that USGC is not and shall not be responsible for or liable for
                                the market value of USGold Tokens, the transferability and/or liquidity of USGold Tokens
                                and/or the availability of any market for USGold Tokens through third parties or
                                otherwise.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            I. TOKEN SECURITY.
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                USGold Tokens may be subject to expropriation and or/theft. Hackers or other malicious
                                groups or organizations may attempt to interfere with the US Gold distribution contract
                                or the USGold Tokens in a variety of ways, including, but not limited to, malware
                                attacks, denial of service attacks, consensus-based attacks, Sybil attacks, smurfing and
                                spoofing. Furthermore, because the Ethereum platform rests on open source software and
                                USGold Tokens are based on open source software, there is the risk that Ethereum smart
                                contracts may contain intentional or unintentional bugs or weaknesses which may
                                negatively affect the USGold Tokens or result in the loss of purchaser’s USGold Tokens,
                                the loss of purchaser’s ability to access or control purchaser’s USGold Tokens or the
                                loss of ETH in purchaser’s account. In the event of such a software bug or weakness,
                                there may be no remedy and holders of USGold Tokens are not guaranteed any remedy,
                                refund or compensation.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            J. ACCESS TO PRIVATE KEYS.
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                USGold Tokens purchased by purchaser are held by purchaser in purchaser’s digital wallet
                                or vault, which requires a private key, or a combination of private keys, for access.
                                Accordingly, loss of requisite private key(s) associated with purchaser’s digital wallet
                                or vault storing USGold Tokens will result in loss of such USGold Tokens, access to
                                purchaser’s USGold Token balance and/or any initial balances in blockchains created by
                                third parties. Moreover, any third party that gains access to such private key(s),
                                including by gaining access to login credentials of a hosted wallet or vault service
                                purchaser uses, may be able to misappropriate purchaser’s USGold Tokens. Company is not
                                responsible for any such losses.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            K. TAX CONSEQUENCES.
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                The purchase and receipt of USGold Tokens may have tax consequences for purchaser or
                                holder. Buyer/holder is solely responsible for compliance with such person’s tax
                                obligations.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            L. FAILURE TO MAP A PUBLIC KEY TO HOLDER’S ACCOUNT.
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                Failure of holder to map a public key to holder’s account may result in third parties
                                being unable to recognize holder’s USG token balance on the Ethereum blockchain.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            M. EXCHANGE & COUNTERPARTY RISKS.
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                If a purchaser sends ETH to USGC and the USGold distribution contract from an exchange
                                or an account that purchaser does not control, USGold Tokens will be allocated to the
                                account that has sent ETH; therefore, purchaser may never receive or be able to recover
                                purchaser’s USGold Tokens. Furthermore, if a purchaser or holder chooses to maintain or
                                hold USGold Tokens through a cryptocurrency exchange or other third party, a purchaser’s
                                or holder’s USGold Tokens may be stolen or lost. In addition, third parties may not
                                recognize purchaser’s claim to any derivative tokens if and when launched by third
                                parties according to the distribution rules set in the USGC Platform. By using the
                                USGold distribution contract and/or by purchasing USGold Tokens, purchaser acknowledges
                                and agrees that purchaser sends ETH to the USGgold distribution contract through an
                                exchange account and/or holds USGgold tokens on a cryptocurrency exchange or with
                                another third party at purchaser’s own and sole risk.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            N. UNCERTAIN REGULATORY FRAMEWORK.
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                The regulatory status of cryptographic tokens, digital assets and blockchain technology
                                is unclear or unsettled in many jurisdictions. It is difficult to predict how or whether
                                governmental authorities will regulate such technologies. It is likewise difficult to
                                predict how or whether any governmental authority may make changes to existing laws,
                                regulations and/or rules that will affect cryptographic tokens, digital assets,
                                blockchain technology and its applications. Such changes could negatively impact USGold
                                Tokens in various ways, including, for example, through a determination that USGold
                                Tokens are regulated financial instruments that require registration. Company may cease
                                the distribution of USGold Tokens, the development of the USGC Platform or cease
                                operations in a jurisdiction in the event that governmental actions make it unlawful or
                                commercially undesirable to continue to do so.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            O. MARKET RISK.
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                The value of an American Eagle one ounce gold coin (“AE Coin”) is affected by many
                                economic factors, including the current market price of GOLD Bullion, the perceived
                                scarcity of the ae Coins and other factors. Some of these factors include the quality
                                and current demand and general market sentiment.
                            </OpenP>
                            <OpenP textAlign='left'>
                                Therefore, because both Bullion and AE Coins can go down in price as well as up,
                                investing in them may not be suitable for everyone. Because all investments, including
                                Bullion and AE Coins, can decline in value, you should understand them well, and have
                                adequate cash reserves and disposable income before considering purchasing USGold
                                Tokens.
                            </OpenP>
                            <OpenP textAlign='left'>
                                All investments, of every type, involve some level of risk and precious metals are no
                                exception. Like many markets, the precious metals market is speculative, and it is
                                unregulated. The value of any Bullion or AE Coin is generally determined by current spot
                                price of the underlying precious metals, and by the supply and demand for these
                                particular items. These prices will fluctuate throughout the day while the precious
                                metals market is open. If you are considering purchasing precious metals as an
                                investment, you should assess the stability of the current market and contact your
                                financial advisor.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                </Accordion>
            </AccordionItemBody>
        </AccordionItem>
        <AccordionItem>
            <AccordionItemTitle>
                5. TERMS OF USE <a name="terms-of-use">&nbsp;</a>
            </AccordionItemTitle>
            <AccordionItemBody>
                <OpenP textAlign='left'>
                    The Website is owned and operated by USGC. Access and use of the Website is provided by USGC to you
                    on condition that you accept these terms of use, and by accessing or using the Website, you agree to
                    these terms of use. If you do not agree to accept and abide by these terms of use of Use you should
                    not access or use the Website.
                </OpenP>
                <OpenP textAlign='left'>
                    USGC may revise and update these terms of use at any time and without notice. You are cautioned to
                    review the terms of use posted on the website periodically. Your continued access or use of the
                    Website after any such changes are posted will constitute your acceptance of these changes.
                </OpenP>
                <Accordion>
                    <AccordionItem>
                        <AccordionItemTitle>
                            A. USE OF THE SITE
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                This website and its contents, including photography and other USGC-owned assets, (the
                                “Content”) are intended for customers of USGC. You may not use the Website or the
                                Content for any purpose not related to your business with USGC. You are specifically
                                prohibited from: (a) downloading, copying, or re-transmitting any or all of the website
                                or the Content without, or in violation of, a written license or agreement with USGC;
                                (b) using any data mining, robots or similar data gathering or extraction methods; (c)
                                manipulating or otherwise displaying the website or the Content by using framing or
                                similar navigational technology; (d) registering, subscribing, unsubscribing, or
                                attempting to register, subscribe, or unsubscribe any party for any USGC product or
                                service if you are not expressly authorized by such party to do so; and (e) using the
                                website or the Content other than for its intended purpose, as determined solely in
                                USGC’s discretion, including but not limited to, to defame, abuse, harass, stalk,
                                threaten or otherwise violate the legal rights (such as rights of privacy) of others,
                                and/or to publish, post, distribute or disseminate any defamatory, infringing, obscene,
                                pornographic, sexual, indecent or unlawful material or information.
                            </OpenP>
                            <OpenP textAlign='left'>
                                You may not interfere with the security of, or otherwise abuse the Website or any system
                                resources, services or networks connected to or accessible through the Website. You may
                                only use the Website for lawful purposes.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            B. INTELLECTUAL PROPERTY
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                All materials, including the organization and presentation of such material, on the
                                Website, including wholly owned brand assets such as photography (the “Materials”) are
                                the property of USGC and its licensors and may be protected by intellectual property
                                laws including laws relating to copyrights, trademarks, trade names, internet domain
                                names and other similar rights.
                            </OpenP>
                            <OpenP textAlign='left'>
                                Unless you have entered into a separate agreement with USGC, any other use of these
                                Materials without USGC's written permission is prohibited. Action taken as a result of
                                use of Materials without express written permission from USGC is at the discretion of
                                USGC and our legal team.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            C. TRADEMARKS
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                USGold, USG, USGC and any other product or service name or slogan contained in the
                                Website are trademarks of USGC and its licensors and may not be copied, imitated or
                                used, in whole or in part, without the prior written permission of USGC or the
                                applicable trademark holder. You may not use any “hidden text” utilizing “USGC” or any
                                other name, trademark or product or service name of USGC without our prior written
                                permission. In addition, the look and feel of the Website, including all page headers,
                                custom graphics, button icons and scripts, is the service mark, trademark and/or trade
                                dress of USGC and may not be copied, imitated or used, in whole or in part, without our
                                prior written permission. All other trademarks, registered trademarks, product names and
                                company names or logos mentioned in the Website are the property of their respective
                                owners. Reference to any products, services, processes or other information, by trade
                                name, trademark, manufacturer, supplier or otherwise does not constitute or imply
                                endorsement, sponsorship or recommendation thereof by us.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            D. SECURITY
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                Purchasers and holders are responsible to implement reasonable and appropriate measures
                                designed to secure access to: (i) any device associated with purchaser/holder and
                                utilized in connection with the purchase of USG Tokens; (ii) private keys to
                                purchaser’s/holder’s wallet or account; and (iii) any other username, passwords or other
                                login or identifying credentials. In the event that a purchaser/holder is no longer in
                                possession of a private keys or any device associated with such person’s account or is
                                not able to provide login or identifying credentials, purchaser/holder may lose all of
                                purchaser/holder’s USG Tokens and/or access to purchaser/holders account and to any
                                rights to receive AE Coins upon redemption. USGC is under no obligation to recover any
                                USG Tokens and purchasers/holders acknowledges, understands and agrees that all
                                purchases of USG Tokens are non-refundable and purchaser will not receive a return of
                                money or other compensation for any USG Tokens purchased.
                            </OpenP>
                            <OpenP textAlign='left'>
                                Upon USGC request, you will immediately provide to USGC information and documents that
                                the USGC , in its sole discretion, deems necessary or appropriate to comply with any
                                laws, regulations, rules or agreements, including without limitation judicial process.
                                Such documents include, but are not limited to, passport, driver’s license, utility
                                bills, photographs of associated individuals, government identification cards, or sworn
                                statements. You consent to USGC disclosing such information and documents in order to
                                comply with applicable laws, regulations, rules or agreements. You acknowledge that USGC
                                may refuse to distribute USG Tokens or redeem such USGC tokens for AE Coins until such
                                requested information is provided.
                            </OpenP>
                            <OpenP textAlign='left'>
                                You acknowledge, understand and agree that: (a) the purchase and receipt of USG Tokens
                                may have tax consequences; (b) you are solely responsible for your compliance with any
                                tax obligations; and (c) USGC bears no liability or responsibility with respect to any
                                tax consequences.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                </Accordion>
            </AccordionItemBody>
        </AccordionItem>
        <AccordionItem>
            <AccordionItemTitle>
                6. GENERAL PROVISIONS
            </AccordionItemTitle>
            <AccordionItemBody>
                <Accordion>
                    <AccordionItem>
                        <AccordionItemTitle>
                            A. ABSENCE OF RELATIONSHIP
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                The sole relationship between USGC and a purchaser or holder is that of purchaser and
                                seller. No other relationship, including, without limitation, any agent-principal
                                relationship, any employee-employer relationship, any franchisee-franchisor
                                relationship, any joint venture relationship or any partnership relationship, between
                                USGC and you exists.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            B. ADDITIONAL ITEMS
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                Additional terms relating to the USGold Tokens (such as prices, methods of payment, our
                                policies with respect to returns, refunds, and cancellations and/or exchanges) may be
                                posted on the site on the pages describing the UST.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            C. ASSIGNMENT
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                You may not assign any of your rights or obligations under this User Agreement without
                                the prior written consent of USGC, which may be granted or withheld by USGC in its sole
                                discretion. This contract, including but not limited to the User Agreement, shall be
                                binding on all permitted assigns and successors of USGC and you.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            D. CONFIDENTIALITY
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                You shall be very careful about disclosing the existence and terms of all your
                                transactions with USGC and should carefully consider any disclosure of such information
                                that you make to third parties.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            E. DISPUTE RESOLUTION
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                Any purchaser or holder of a USG token agrees to be bound to the following terms:
                            </OpenP>
                            <OpenP textAlign='left'>
                                USGC and any purchaser/holder shall cooperate in good faith to resolve any dispute,
                                controversy or claim arising out of, relating to or in connection with this Agreement,
                                including with respect to the formation, applicability, breach, termination, validity or
                                enforceability thereof (a “Dispute”). If the parties are unable to resolve a Dispute
                                within ninety (90) days of notice of such Dispute being received by all parties, such
                                Dispute shall be finally settled by Binding Arbitration as defined below.
                            </OpenP>
                            <OpenP textAlign='left'>
                                Any Dispute not resolved within 90 days as set forth above shall be referred to and
                                finally resolved by arbitration under the rules of the State of Wyoming in effect at the
                                time of the arbitration, except as they may be modified herein or by mutual agreement of
                                the Parties. The number of arbitrators shall be one who shall be selected by USGC. The
                                seat, or legal place, of arbitration shall be Salt Lake City, Utah. The language to be
                                used in the arbitral proceedings shall be English. The arbitration award shall be final
                                and binding on the Parties (“Binding Arbitration”). The parties undertake to carry out
                                any award without delay and waive their right to any form of recourse insofar as such
                                waiver can validly be made. Judgment upon the award may be entered by any court having
                                jurisdiction thereof or having jurisdiction over the relevant party or its assets. Each
                                party will each pay their respective attorneys’ fees and expenses.
                            </OpenP>
                            <OpenP textAlign='left'>
                                Any dispute arising out of or related to this Agreement is personal to the
                                purchaser/holder USGC and will not be brought as a class arbitration, class action or
                                any other type of representative proceeding. There will be no class arbitration or
                                arbitration in which an individual attempts to resolve a dispute as a representative of
                                another individual or group of individuals. Further, a dispute cannot be brought as a
                                class or other type of representative action, whether within or outside of arbitration,
                                or on behalf of any other individual or group of individuals.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            F. FORCE MAJEURE
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                If USGC cannot perform any obligation hereunder as a result of any event that is beyond
                                its control, USGC’s delay or failure to perform such obligation shall be excused and
                                USGC shall not be liable for any damages as a result of, or in connection with, such
                                delay or such failure.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            G. NOTICES
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                Except as otherwise provided herein, all notices and other communications to USGC shall
                                be directed to USGC, c/o Chief Legal Counsel,1603 Capitol Ave., Suite 303, Cheyenne, WY
                                82001 USA, and all other notices and other communications to you shall be directed, at
                                the option of USGC, to the address that you provided to USGC at the time of your
                                registration.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            H. RECORDING OF PHONE CONVERSATIONS
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                USGC may record phone conversations between us, and you expressly consent to the
                                recording of such phone conversations.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            I. ABSENCE OF WAIVERS
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                A decision or a failure by USGC to take action with respect to any non-compliance by you
                                of your obligations to USGC or to insist upon strict adherence to any term in the User
                                Agreement, does not affect the ability of USGC with respect to any other non-compliance
                                by you, and does not waive or limit USGC’s right thereafter to insist upon strict
                                adherence to that or any other term, whether of a similar or dissimilar nature.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            J. PRICING OR TYPOGRAPHICAL ERRORS
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                We work hard to provide accurate pricing information to you. However, pricing or
                                typographical errors may occur. In the event that an item is listed at an incorrect list
                                price or with incorrect information due to an error in pricing, USGC shall have the
                                right, at our sole discretion, to refuse or cancel any purchase order. Prices and
                                availability are subject to change without notice. If a refund is provided or there is
                                any delay in sending you your Products or your payment, as the case may be, you waive
                                any right to any claim or actual damages of any kind or nature whatsoever.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            K. SEVERABILITY
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                The User Agreement is intended to be enforceable to the fullest extent permitted by
                                applicable law. If any term of the User Agreement is held to be unenforceable for any
                                reason, such unenforceability shall not affect the other terms of the User Agreement,
                                and the term that would otherwise be unenforceable shall be enforced to the fullest
                                extent that it would be enforceable.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            L. AMENDMENT
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                USGC may amend the User Agreement at any time by posting the amended terms on the
                                Website and such amendments shall become effective immediately.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            M. ENTIRE AGREEMENT
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                The User Agreement, the Privacy Policy, the Token Purchase Agreement and confirmations
                                of the Sale or Redemption transactions hereunder set forth the entire understanding
                                between USGC and you with respect to the subject matter of our transactions.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            Q. USER CONTENT AND CONDUCT
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                At times, if and where applicable at the Website, you may be invited to post User
                                Content. You understand that all User Content, whether you have publicly posted on a
                                forum or privately transmitted to another or to us, is your sole responsibility. Though
                                the Website is designed to be a safe place to share such User Content, USGC cannot
                                guaranty that other users will not misuse the User Content that you share. If you have
                                any User Content that you would like to keep confidential or do not want others to use,
                                do not post it to the Website. Under no circumstances will USGC be liable in any way for
                                any User Content, including, but not limited to, any errors or omissions in any User
                                Content, or for any loss or damage of any kind incurred as a result of the use of any
                                User Content posted, emailed or otherwise transmitted via the Website. The USGC entities
                                are not responsible for a user’s misuse or misappropriation of any User Content you
                                posted at the Website.
                            </OpenP>
                            <OpenP textAlign='left'>
                                By posting any User Content at the Website, you hereby grant USGC and its designees a
                                royalty-free, fully paid-up, perpetual, irrevocable, non-exclusive and fully
                                sub-licensable right and license to use, reproduce, modify, adapt, publish, translate,
                                combine with other works, create derivative works from, distribute, perform, edit and
                                display such User Content (in whole or part), throughout the world in any form, media,
                                or technology now known or later developed. You specifically waive any “moral rights” in
                                and to the User Content. The foregoing grant includes, without limitation, any
                                copyrights and other intellectual property rights in and to your User Content. You
                                represent and warrant that: (a) you own the User Content posted by you or otherwise have
                                the right to grant the license set forth in this section; and (b) the posting of your
                                User Content at the Website does not violate the privacy rights, publicity rights,
                                copyrights, contract rights or any other rights of any person. You agree to pay for all
                                royalties, fees, and any other monies owing any person by reason of any User Content you
                                post at the Website.
                            </OpenP>
                            <OpenP textAlign='left'>
                                You acknowledge and agree that USGC may preserve User Content and may also disclose User
                                Content if required to do so by law or in the good faith belief that such preservation
                                or disclosure is reasonably necessary to: (a) comply with legal process; (b) enforce
                                this Agreement; (c) respond to claim(s) or assertion(s) that any User Content violates
                                the rights of third-parties; or (d) protect the rights, property, or personal safety of
                                USGC, its users and the public. You understand that the technical processing and
                                transmission of the Website, including your User Content, may involve transmissions over
                                various networks and changes to conform and adapt to technical requirements of
                                connecting networks or devices.
                            </OpenP>
                            <OpenP textAlign='left'>
                                You agree to each of the conditions in this Agreement and further agree that each of
                                these conditions applies forever and broadly with regard to USGC worldwide. Posting of
                                User Content to or through the Website, including ideas or disclosures of opinions, is
                                voluntary on your part. No confidential or contractual relationship is established by
                                your posting of User Content or is to be implied by our review or subsequent use of your
                                User Content. USGC shall not be liable for any disclosure of any User Content, including
                                opinion(s) or suggestion(s), you post to or through the Website. USGC shall be entitled
                                to unrestricted use of any User Content it may receive from you at the Website, for any
                                purpose whatsoever, commercial or otherwise, without compensation to you as the provider
                                of the User Content.
                            </OpenP>
                            <OpenP textAlign='left'>
                                You agree that you will not use the Website to:
                            </OpenP>
                            <OpenUl>
                                <OpenLi> upload, post, email or otherwise transmit any User Content that is unlawful,
                                    harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, libelous,
                                    invasive of another's privacy, hateful, or racially, ethnically or otherwise
                                    objectionable;</OpenLi>
                                <OpenLi> harm minors in any way;</OpenLi>
                                <OpenLi> impersonate any person or entity, including, but not limited to a
                                    representative of USGC, or falsely state or otherwise misrepresent your affiliation
                                    with a person or entity;</OpenLi>
                                <OpenLi> forge headers or otherwise manipulate identifiers in order to disguise the
                                    origin of any User Content transmitted through the Website;</OpenLi>
                                <OpenLi> upload, post, email or otherwise transmit any User Content that you do not have
                                    a right to transmit under any law or under contractual or fiduciary relationships
                                    (such as inside information, proprietary and confidential information learned or
                                    disclosed as part of employment relationships or under nondisclosure
                                    agreements);</OpenLi>
                                <OpenLi> upload, post, email or otherwise transmit any User Content that infringes any
                                    patent, trademark, trade secret, copyright or other proprietary rights of any
                                    party;</OpenLi>
                                <OpenLi> upload, post, email or otherwise transmit any unsolicited or unauthorized
                                    advertising, promotional materials, "junk mail," "spam," "chain letters," "pyramid
                                    schemes," or any other form of solicitation, except in those areas that are
                                    designated for such purpose;</OpenLi>
                                <OpenLi> upload, post, email or otherwise transmit any material that contains software
                                    viruses or any other computer code, files or programs designed to interrupt,
                                    emulate, destroy or limit the functionality of any computer software or hardware or
                                    telecommunications equipment;</OpenLi>
                                <OpenLi> disrupt the normal flow of dialogue, cause a screen to "scroll" faster than
                                    other users of the Website are able to type, or otherwise act in a manner that
                                    negatively affects other users' ability to engage in real-time exchanges;</OpenLi>
                                <OpenLi> interfere with or disrupt the Website or servers or networks connected to the
                                    Website, or disobey any requirements, procedures, policies or regulations of
                                    networks connected to the Website;</OpenLi>
                                <OpenLi> "stalk" or otherwise harass another; or</OpenLi>
                                <OpenLi> collect or store personal data about other Website users.</OpenLi>
                            </OpenUl>
                            <OpenP textAlign='left'>
                                You understand that by using the Website, you may be exposed to User Content created by
                                others that is offensive, indecent or objectionable. USGC does not endorse or have
                                control over User Content. User Content is not reviewed by USGC prior to posting and
                                does not reflect the opinions or policies of USGC. USGC makes no representations or
                                warranties, express or implied as to User Content or the accuracy and reliability of
                                User Content or any other material or information that you may access through the
                                Website. USGC assumes no responsibility for monitoring the Website for inappropriate
                                submissions or conduct. If at any time USGC chooses, in its sole discretion, to monitor
                                the Website, USGC nonetheless assumes no responsibility for User Content, has no
                                obligation to modify or remove any inappropriate User Content, and has no responsibility
                                for the conduct of the Website users submitting any such User Content. Notwithstanding
                                the foregoing, USGC and its designees shall have the right to remove any User Content
                                that violates this Agreement or is otherwise objectionable, in USGC’s sole discretion.
                                You agree that you must evaluate, and bear all risks associated with, the use of any
                                User Content, including any reliance on the accuracy, completeness, or usefulness of
                                such User Content. In this regard, you acknowledge that you may not rely on any User
                                Content.
                            </OpenP>
                            <OpenP textAlign='left'>
                                You are solely responsible for your interactions with other Website users. USGC reserves
                                the right, but has no obligation, to monitor disputes between you and other Website
                                users and to terminate your Website access if USGC determines, in its sole discretion,
                                that doing so is prudent.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                </Accordion>
            </AccordionItemBody>
        </AccordionItem>
        <AccordionItem>
            <AccordionItemTitle>
                7. DEFINITIONS
            </AccordionItemTitle>
            <AccordionItemBody>
                <OpenP textAlign='left'>
                    The following terms have the following meanings in the User Agreement:
                </OpenP>
                <Accordion>
                    <AccordionItem>
                        <AccordionItemTitle>
                            A. USGC
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                The term "USGC" means US Gold Currency, Inc., a Wyoming corporation.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            B. BUSINESS DAY(S)
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                The term "Business Day(s)" means a day other than a Saturday, a Sunday or a day that is
                                a holiday under the federal law of the United States of America and/or the laws of the
                                State of Wyoming.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            C. COIN(S)
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                The term “Coin(s)” means a stamped piece of metal of a known weight and fineness issued
                                by a sovereign government.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            D. FAIR MARKET VALUE
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                The term “Fair Market Value” means the Fair Market Value of the Product as determined by
                                USGC, in its reasonable discretion, as of 5 p.m. (MT) on the date of cancellation of the
                                Sales Order or the Purchase Order.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            E. MARKET GAIN
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                The term “Market Gain” means:<br/>
                                When a Sales Order or Sale, as the case may be, is cancelled by USGC, Market Gain occurs
                                when the Fair Market Value of the Product(s) on the date of the cancelled Sales Order or
                                Sale is greater than the original sales price of the Product(s) as it appears on the
                                cancelled Sales Order.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            F. MARKET LOSS
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                The term “Market Loss” means:<br/>
                                When a Sales Order or Sale, as the case may be, is cancelled by USGC, Market Loss occurs
                                when the Fair Market Value of the Product(s) on the date of the cancelled Sales Order or
                                Sale is less than the original sales price of the Product(s) as it appears on the
                                cancelled Sales Order.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            G. ORDER(S)
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                The term “Order(s)” means, as applicable, a confirmed Sale by a customer or Redemption
                                by USGC, whether such Order was made through the Website, by telephone, in person or
                                otherwise.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            H. PRECIOUS METAL(S)
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                The term “Precious Metal(s)” means gold, silver and platinum along with the platinum
                                group of elements: rhodium, osmium, ruthenium, palladium and iridium. Precious Metals
                                are characteristically lustrous, ductile, rare and nonreactive.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            I. PURCHASE(S)
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                The term "Purchase(s)” means the acquisition of a USGold Token by USGC from a customer,
                                whether made through the Website, with the number, quality and price confirmed. A
                                Purchase is distinct from the acquisition by a customer from USGC of a USGold Token,
                                which is treated as a Sale hereunder.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            J. REDEMPTION ORDER(S)
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                The term “Redemption Order(s)” means a request to exchange a USG for an AE Coin placed
                                by a customer.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            K. SALE(S)
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                The term "Sale(s)” means the acquisition of USG by a customer from USGC, whether made
                                through the Website, by telephone, in person, or otherwise, with the number, quality and
                                price confirmed.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            L. SALE ORDER(S)
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                The term “Sale Order(s)” means an Order placed by a customer with USGC for the
                                acquisition by the customer of Product(s) from USGC.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            M. USER CONTENT
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                The term “User Content” means content posted by you on the Website, including without
                                limitation any images or testimonials.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            N. WEBSITE
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                The term "Website” means the websites located at https://USGold.io.
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                </Accordion>
            </AccordionItemBody>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemTitle>
                SCHEDULE 1
            </AccordionItemTitle>
            <AccordionItemBody>
                <Accordion>
                    <AccordionItem>
                        <AccordionItemTitle>
                            INDIVIDUAL
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                Prior to opening an account, USGC will collect the following information for all
                                accounts, if applicable, for any person, entity or organization that is opening a new
                                account and whose name is on the account:<br/>
                            </OpenP>
                            <OpenP textAlign='left'>
                                (1) the name;
                            </OpenP>
                            <OpenP textAlign='left'>
                                (2) date of birth (for an individual);
                            </OpenP>
                            <OpenP textAlign='left'>
                                (3) an address, which will be a residential or business street address (for an
                                individual), an Army Post Office (APO) or Fleet Post Office (FPO) box number, or
                                residential or business street address of next of kin or another contact individual (for
                                an individual who does not have a residential or business street address), or a
                                principal place of business, local office, or other physical location (for a person
                                other than an individual); and
                            </OpenP>
                            <OpenP textAlign='left'>
                                (4) an identification number, which will be a taxpayer identification number (for U.S.
                                persons), or one or more of the following: a taxpayer identification number, passport
                                number and country of issuance, alien identification card number, or number and country
                                of issuance of any other government-issued document evidencing nationality or residence
                                and bearing a photograph or other similar safeguard (for non-U.S. persons).
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemTitle>
                            LEGAL ENTITY
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                At the time of opening an account for a legal entity customer, an authorized officer,
                                manager, or other authorized person will identify any individual that is a beneficial
                                owner of the legal entity customer by identifying any individuals who directly or
                                indirectly own 25% or more of the equity interests of the legal entity customer, and any
                                individual with significant responsibility to control, manage, or direct a legal entity
                                customer. The following information will be collected for each beneficial owner:
                            </OpenP>
                            <br/>
                            <OpenP textAlign='left'>
                                (1) the name;
                            </OpenP>
                            <OpenP textAlign='left'>
                                (2) date of birth (for an individual);
                            </OpenP>
                            <OpenP textAlign='left'>
                                (3) an address, which will be a residential or business street address (for an
                                individual), or an Army Post Office (APO) or Fleet Post Office (FPO) box number, or
                                residential or business street address of next of kin or another contact individual (for
                                an individual who does not have a residential or business street address); and
                            </OpenP>
                            <OpenP textAlign='left'>
                                (4) an identification number, which will be a Social Security number (for U.S. persons),
                                or one or more of the following: a passport number and country of issuance, or other
                                similar identification number, such as an alien identification card number, or number
                                and country of issuance of any other government-issued document evidencing nationality
                                or residence and bearing a photograph or other similar safeguard (for non-U.S. persons).
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            TERMS OF PURCHASE
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <OpenP textAlign='left'>
                                This USGC TERMS OF PURCHASE (these “Terms”) contains the terms and conditions that
                                govern your use of the USG distribution smart contract (the “USGC Distribution
                                Contract”); use of the related ERC-20 USGold Token smart contact (the “USGold Token
                                Contract”); and purchase of the ERC-20 compatible tokens distributed on the Ethereum
                                blockchain (the “USGold Tokens”) and is an agreement between you or the entity that you
                                represent (“Purchaser” or “you”) and US Gold Currency, Inc., a Wyoming corporation
                                (together with its affiliates, “Company”). Purchaser and Company are herein referred to
                                individually as a “Party” and collectively, as the “Parties”.
                            </OpenP>
                            <OpenP textAlign='left'>
                                NOW, THEREFORE, in consideration of the mutual representations, warranties and
                                agreements contained in these Terms, and for other good and valuable consideration, the
                                receipt and sufficiency of which are hereby acknowledged, Company and Purchaser agree as
                                follows:
                            </OpenP>
                            <OpenP textAlign='left'>
                                IMPORTANT INFORMATION:
                            </OpenP>
                            <OpenP textAlign='left'>
                                PLEASE READ THIS AGREEMENT CAREFULLY AND IN ITS ENTIRETY.
                            </OpenP>
                            <OpenP textAlign='left'>
                                Purchaser acknowledges, understands and agrees to the following:<br/>
                                BINDING AGREEMENT: Purchaser understands and agrees that Purchaser is subject to and
                                bound by these Terms by virtue of Purchaser’s purchase of USGold Tokens.
                            </OpenP>

                            <OpenP>
                                <a href={PurchaseAgreementDownload} download>Download Full Purchase Agreement Here</a>
                            </OpenP>
                        </AccordionItemBody>
                    </AccordionItem>
                </Accordion>
            </AccordionItemBody>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemTitle>
                ARTICLE 1
            </AccordionItemTitle>
            <AccordionItemBody>
                <strong>ACCEPTANCE OF TERMS AND PURCHASE OF USG TOKENS</strong><br/>
                <OpenP textAlign='left'>
                    Section 1.1 <br/>
                    These Terms shall be effective and binding on the Parties when Purchaser: (a) clicks the check box
                    on the official https://USGold.io website (the “Website”) to indicate that Purchaser has read,
                    understands and agrees to the terms of these Terms; or, if earlier (b) upon Company’s receipt of
                    payment from Purchaser or (c) upon purchasers purchase of a USGold Token through the Website or on
                    an exchange. Purchaser agrees to be bound on this basis, and confirms that Purchaser has read in
                    full and understands this Agreement and the terms on which Purchaser is bound.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 1.2<br/>
                    Company has established User Agreement, as may be amended from time to time, for the Website located
                    at https://usgold.io/user-agreement, which are hereby incorporated by reference. Purchaser has read,
                    understands and agrees to those terms.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 1.3 USGold Tokens.<br/>
                    (a) Purpose. A USGold Token represents the beneficial ownership of and the right to receive upon
                    redemption, one American Eagle one ounce gold coin (“AE Coin“) and the beneficial ownership of such
                    coin, which shall be owned for the benefit of the holder by US Gold Token Holders Trust and stored
                    in a secure vault with the Custodian pending redemption by the holder. The USGold Tokens do not have
                    any rights, uses, purpose, attributes, functionalities or features, express or implied. The USGold
                    Tokens represent the ownership of a US Currency and as such are subject to rules regarding the sale
                    of currencies. USGold Tokens are not designed to be an investment, security, commodity, a swap on a
                    currency, security or commodity, or any other kind of financial instrument. Upon purchase of a
                    USGOLD Token, the Company will transfer one AE Coin to a trustee who will act as owner of the AE
                    Coin for the benefit of the holder of the USGOLD Token until such AE Coin is redeemed. The AE Coin
                    will be stored in a secure facility until such time.
                </OpenP>
            </AccordionItemBody>
        </AccordionItem>
        <AccordionItem>
            <AccordionItemTitle>
                ARTICLE 2
            </AccordionItemTitle>
            <AccordionItemBody>
                <OpenP textAlign='left'>
                    <strong>USG TOKEN DISTRIBUTION</strong><br/>
                    Section 2.1<br/>
                    <em>Allocation and Distribution of USGold Tokens.</em> The Company intends to allocate and
                    distribute USGold Tokens (the “Token Distribution”) in will take place on an ongoing basis. Company
                    will provide specific procedures on how Purchaser may purchase USGold Tokens through the Website. By
                    purchasing USGold Tokens, Purchaser represents and acknowledges that Purchaser understands and has
                    no objection to such procedures and specifications. Failure to use the Website and follow such
                    procedures may result in Purchaser not receiving any USGold Tokens. Any Purchaser of USGold Tokens
                    may lose some or all of the amounts paid for USGold Tokens. The access or use of the USGOLD
                    Distribution Contract and/or the receipt or purchase of USGold Tokens through any other means other
                    than the official Website are not sanctioned or agreed to in any way by the Company.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 2.2<br/>
                    <em>No Representations and Warranties.</em> Except as set forth herein, the Company makes no
                    representations or warranties, express or implied, including, without limitation, any warranties of
                    title or implied warranties of merchantability or fitness for a particular purpose with respect to
                    the USGOLD Distribution Contract or the USGold Tokens or their utility, or the ability of anyone to
                    purchase or use the USGold Tokens for any purpose. Without limiting the foregoing, none of the
                    Company Parties represent or warrant that the process of purchasing and/or receiving the USGold
                    Tokens will be uninterrupted or error-free or that the USGold Tokens are reliable and error-free. As
                    a result, Purchaser acknowledges and understands that Purchaser may never receive USGold Tokens and
                    may lose the entire amount Purchaser paid to Company. Purchaser shall provide an accurate digital
                    wallet address to Company for receipt of any USGold Tokens distributed to Purchaser pursuant to the
                    USGOLD Distribution Contract.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 2.3<br/>
                    <em>Not an Offering of Securities, Commodities, or Swaps.</em> The sale of USGold Tokens is not an
                    offering of sale or to purchase securities and the USGold Tokens themselves are not securities,
                    commodities, swaps on either securities or commodities, or a financial instrument of any kind.
                    Purchaser acknowledges and agrees that the purchase and sale of USGold Tokens are not subject to the
                    protections of any laws governing those types of financial instruments. This Agreement and all other
                    documents referred to in these Terms does ot constitute a prospectus or offering document, and are
                    not an offer to sell, nor the solicitation of an offer to buy an investment, a security, commodity,
                    or a swap on either a security or commodity.

                </OpenP>
            </AccordionItemBody>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemTitle>
                ARTICLE 3
            </AccordionItemTitle>
            <AccordionItemBody>
                <OpenP textAlign='left'>
                    <strong>NO OTHER RIGHTS CREATED</strong><br/>
                    Section 3.1<br/>
                    <em>No Claim, Loan or Ownership Interest.</em> The purchase of USGold Tokens: (a) represents a
                    digital representation of beneficial ownership of a physical American Eagle one ounce gold coin and
                    entitles the Purchaser to receive such coin upon request and does not provide Purchaser with rights
                    of any form with respect to the Company or its revenues or assets, including, but not limited to,
                    any voting, distribution, redemption, liquidation, proprietary (including all forms of intellectual
                    property), or other financial or legal rights; (b) is not a debt obligation of or a loan to Company;
                    and (c) does not provide Purchaser with any equity ownership or other interest in Company.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 3.2<br/>
                    Intellectual Property.<br/>
                    Company retains all right, title and interest in all of Company’s intellectual property, including,
                    without limitation, inventions, ideas, concepts, code, discoveries, processes, marks, methods,
                    software, compositions, formulae, techniques, information and data, whether or not patentable,
                    copyrightable or protectable in trademark, and any trademarks, copyright or patents based thereon.
                    Purchaser may not use any of Company’s intellectual property for any reason without Company’s prior
                    written consent.
                </OpenP>
            </AccordionItemBody>
        </AccordionItem>
        <AccordionItem>
            <AccordionItemTitle>
                ARTICLE 4
            </AccordionItemTitle>
            <AccordionItemBody>
                <OpenP textAlign='left'>
                    <strong>SECURITY AND DATA; TAXES</strong><br/>
                    Section 4.1<br/>
                    Security and Data Privacy.<br/>
                    (a) Purchaser’s Security. Purchaser will implement reasonable and appropriate measures designed to
                    secure access to: (i) any device associated with Purchaser and utilized in connection with
                    Purchaser’s purchase of USG Tokens; (ii) private keys to Purchaser’s wallet or account; and (iii)
                    any other username, passwords or other login or identifying credentials. In the event that Purchaser
                    is no longer in possession of Purchaser’s private keys or any device associated with Purchaser’s
                    account or is not able to provide Purchaser’s login or identifying credentials, Purchaser may lose
                    all of Purchaser’s USG Tokens and/or access to Purchaser’s account. Company is under no obligation
                    to recover any USG Tokens and Purchaser acknowledges, understands and agrees that all purchases of
                    USG Tokens are non-refundable and Purchaser will not receive a return of money or other compensation
                    for any USGold Tokens purchased.
                </OpenP>
                <OpenP textAlign='left'>
                    (b) Additional Information. Upon Company’s request, Purchaser will immediately provide to Company
                    information and documents that the Company, in its sole discretion, deems necessary or appropriate
                    to comply with any laws, regulations, rules or agreements, including without limitation judicial
                    process. Such documents include, but are not limited to, passport, driver’s license, utility bills,
                    photographs of associated individuals, government identification cards, or sworn statements.
                    Purchaser consents to Company disclosing such information and documents in order to comply with
                    applicable laws, regulations, rules or agreements. Purchaser acknowledges that Company may refuse to
                    distribute USG Tokens to Purchaser until such requested information is provided.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 4.2<br/>
                    Taxes.<br/>
                    Purchaser acknowledges, understands and agrees that: (a) the purchase and receipt of USG Tokens may
                    have tax consequences for Purchaser; (b) Purchaser is solely responsible for Purchaser’s compliance
                    with Purchaser’s tax obligations; and (c) Company bears no liability or responsibility with respect
                    to any tax consequences to Purchaser.
                </OpenP>
            </AccordionItemBody>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemTitle>
                ARTICLE 5
            </AccordionItemTitle>
            <AccordionItemBody>
                <OpenP textAlign='left'>
                    <strong>REPRESENTATIONS AND WARRANTIES OF PURCHASER</strong><br/>
                    By buying USGold Tokens, Purchaser represents and warrants to Company that:<br/>
                    Section 5.1 Authority. Purchaser has all requisite power and authority to execute and deliver this
                    agreement, to purchase the USGold Tokens, to use the USGOLD Distribution Contract and purchase
                    USGold Tokens, and to carry out and perform its obligations under these Terms
                </OpenP>
                <OpenP textAlign='left'>
                    (a) If an individual, Purchaser is at least 18 years old and of sufficient legal age and capacity to
                    purchase USG Tokens.
                </OpenP>
                <OpenP textAlign='left'>
                    (b) If an entity or other legal person, Purchaser is duly organized, validly existing and in good
                    standing under the laws of its domiciliary jurisdiction and each jurisdiction where it conducts
                    business.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 5.2 No Conflict. The execution, delivery and performance of these Terms will not result in
                    any violation of, be in conflict with, or constitute a material default under, with or without the
                    passage of time or the giving of notice: (a) any provision of Purchaser’s organizational documents,
                    if applicable; (b) any provision of any judgment, decree or order to which Purchaser is a party, by
                    which it is bound, or to which any of its material assets are subject; (c) any material agreement,
                    obligation, duty or commitment to which Purchaser is a party or by which it is bound; or (d) any
                    laws, regulations or rules applicable to Purchaser.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 5.3 No Consents or Approvals. The execution and delivery of, and performance under, these
                    Terms require no approval or other action from any governmental authority or person other than
                    Purchaser.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 5.4 Purchaser Status. Purchaser is not subject to any of the disqualifying events listed in
                    Rule 506(d)(1) of Regulation D under the Securities Act of 1933 (a “Purchaser Event”), and there is
                    no proceeding or investigation pending or, to the knowledge of Purchaser, threatened by any
                    governmental authority, that would reasonably be expected to become the basis for a Purchaser Event.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 5.5 Section 1.1 Purchaser has sufficient knowledge and experience in business and financial
                    matters, including a sufficient understanding of blockchain or cryptographic tokens and other
                    digital assets, smart contracts, storage mechanisms (such as digital or token wallets),
                    blockchain-based software systems and blockchain technology, to be able to evaluate the risks and
                    merits of Purchaser’s purchase of USGold Tokens, including but not limited, to the matters set forth
                    in these Terms, and is able to bear the risks thereof, including loss of all amounts paid, loss of
                    USGold Tokens, and liability to the Company Parties and others for its acts and omissions, including
                    with limitation those constituting breach of these Terms, negligence, fraud or willful misconduct.
                    Purchaser has obtained sufficient information in order to make an informed decision to purchase
                    USGold Tokens.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 5.6 Funds; Payments.
                    (a) Funds. The funds, including any fiat, virtual currency or cryptocurrency, Purchaser uses to
                    purchase USG Tokens are not derived from or related to any unlawful activities, including but not
                    limited to money laundering or terrorist financing, and Purchaser will not use the USG Tokens to
                    finance, engage in, or otherwise support any unlawful activities.
                </OpenP>
                <OpenP textAlign='left'>
                    (b) Payments. All payments by Purchaser under these Terms will be made only in Purchaser’s name,
                    from a digital wallet or bank account not located in a country or territory that has been designated
                    as a “non-cooperative country or territory” by the Financial Action Task Force, and is not a
                    “foreign shell bank” within the meaning of the U.S. Bank Secrecy Act (31 U.S.C. § 5311 et seq.), as
                    amended, and the regulations promulgated thereunder by the Financial Crimes Enforcement Network, as
                    such regulations may be amended from time to time.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 5.7 Miscellaneous Regulatory Compliance.
                    (a) Anti-Money Laundering; Counter-Terrorism Financing. To the extent required by applicable law,
                    Purchaser agrees to comply with all anti-money laundering and counter-terrorism financing
                    requirements which the Company may require.
                </OpenP>
                <OpenP textAlign='left'>
                    (b) Sanctions Compliance. Neither Purchaser, nor any person having a direct or indirect beneficial
                    interest in Purchaser or USGold Tokens being acquired by Purchaser, or any person for whom Purchaser
                    is acting as agent or nominee in connection with USGold Tokens, is the subject of sanctions
                    administered or enforced by any country or government (collectively, “Sanctions”) or is organized or
                    resident in a country or territory that is the subject of country-wide or territory-wide Sanctions.
                </OpenP>
            </AccordionItemBody>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemTitle>
                ARTICLE 6
            </AccordionItemTitle>
            <AccordionItemBody>
                <OpenP textAlign='left'>
                    <strong>DISCLAIMERS</strong><br/>
                    Section 6.1 Purchaser expressly acknowledges, understands and agrees that Purchaser is using the USG
                    Distribution Contract and purchasing USG Tokens at the Purchaser’s sole risk and that the USG
                    Distribution Contract and USG Tokens are each provided, used and acquired on an “AS IS” and on an
                    “AS AVAILABLE” basis without representations, warranties, promises or guarantees whatsoever of any
                    kind by Company and Purchaser shall rely on its own examination and investigation thereof.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 6.2 No Representation or Warranty. (A) COMPANY DOES NOT MAKE AND EXPRESSLY DISCLAIMS ALL
                    REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED OR STATUTORY; AND (B) WITH RESPECT TO THE USG
                    DISTRIBUTION CONTRACT AND THE USG TOKENS, COMPANY SPECIFICALLY DOES NOT REPRESENT OR WARRANT AND
                    EXPRESSLY DISCLAIMS ANY REPRESENTATION OR WARRANTY, EXPRESS, IMPLIED OR STATUTORY, INCLUDING WITHOUT
                    LIMITATION, ANY REPRESENTATIONS OR WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, USAGE,
                    SUITABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE, OR AS TO THE WORKMANSHIP OR TECHNICAL CODING
                    THEREOF, OR THE ABSENCE OF ANY DEFECTS THEREIN, WHETHER LATENT OR PATENT.
                </OpenP>
            </AccordionItemBody>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemTitle>
                ARTICLE 7
            </AccordionItemTitle>
            <AccordionItemBody>
                <OpenP textAlign='left'>
                    <strong>RISKS</strong><br/>
                    USGOLD TOKENS MAY HAVE NO VALUE. PURCHASER MAY LOSE ALL AMOUNTS PAID. Purchaser has carefully
                    reviewed, acknowledges, understands and assumes the following risks, as well as all other risks
                    associated with the USGold Tokens (including those not discussed herein), all of which could render
                    the USGold Tokens worthless or of little value:
                </OpenP>
                <OpenP textAlign='left'>
                    Section 7.1 Basis for Purchase. Purchasing USGold Tokens is at Purchaser’s sole risk and that the
                    USG Tokens are each provided, used and acquired on an “AS IS” and on an “AS AVAILABLE” basis without
                    representations, warranties, promises or guarantees whatsoever of any kind by Company. Purchaser
                    must rely on its own examination and investigation thereof.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 7.2 Purchase Price Risk. There are no guarantees as to the price of USG Tokens purchased by
                    Purchaser and no guarantees that the price per USG Token determined for each period by the market
                    will be equal to or higher than the price paid by Purchaser. There is the possibility that the price
                    per USGold Token will fall below the price paid by initial Purchasers of USGold Tokens during the
                    Distribution Period. Company reserves the right to change the duration of the Distribution Period
                    for any reason, including, without limitation, bugs in the Distribution Contract or the
                    unavailability of the Website or other unforeseen procedural or security issues.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 7.3 Blockchain Delay Risk. On the Ethereum blockchain, timing of block production is
                    determined by proof of work so block production can occur at random times. For example, Token
                    transfers in a given open ledger may not be included in the next last-closed ledger, and may be
                    included in the candidate set for the beginning of the consensus process on the next open ledger. As
                    a result, the last-closed ledger may not include Purchaser’s transaction at the time Purchaser
                    expects and Purchaser may not receive Tokens on the same day Purchaser purchases the Tokens; or, ETH
                    contributed to the USG Distribution Contract in the final seconds of a distribution period may not
                    get included for that period. Purchaser acknowledges and understands that the Ethereum blockchain
                    may not include the Purchaser’s transaction at the time Purchaser expects and Purchaser may not
                    receive USG Tokens the same day Purchaser sends ETH.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 7.4 Ethereum Blockchain. The Ethereum blockchain is prone to periodic congestion during
                    which transactions can be delayed or lost. Individuals may also intentionally spam the Ethereum
                    network in an attempt to gain an advantage in purchasing cryptographic tokens. Purchaser
                    acknowledges and understands that Ethereum block producers may not include Purchaser’s transaction
                    when Purchaser wants or Purchaser’s transaction may not be included at all.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 7.5 Third Party Attacks. Transactions within the Platform may be delayed or lost due to
                    operational error or malicious attacks by third parties. Purchaser acknowledges and understands that
                    the last-closed ledger may not include Purchaser’s transaction when Purchaser wants or expects and
                    that Purchaser’s transaction may be excluded or discarded entirely.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 7.6 Operational Error. Purchaser may never receive USG Tokens and may lose the entire amount
                    Purchaser paid to Company for such USG Tokens as a result of interruptions and operational errors in
                    the process of purchasing or receiving the USG Tokens.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 7.7 Ability to Transact or Resell. Purchaser may be unable to sell or otherwise transact in
                    USG Tokens at any time, or for the price Purchaser paid. By purchasing USG Tokens, Purchaser
                    acknowledges, understands and agrees that: (Company is not and shall not be responsible for or
                    liable for the market value of USG Tokens, the transferability and/or liquidity of USGold Tokens
                    and/or the availability of any market for USG Tokens through third parties or otherwise.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 7.8 Token Security. USGold Tokens may be subject to expropriation and or/theft. Hackers or
                    other malicious groups or organizations may attempt to interfere with the USG Distribution Contract
                    or the USG Tokens in a variety of ways, including, but not limited to, malware attacks, denial of
                    service attacks, consensus-based attacks, Sybil attacks, smurfing and spoofing. Furthermore, because
                    the Ethereum platform rests on open source software and USG Tokens are based on open source
                    software, there is the risk that Ethereum smart contracts may contain intentional or unintentional
                    bugs or weaknesses which may negatively affect the USG Tokens or result in the loss of Purchaser’s
                    USG Tokens, the loss of Purchaser’s ability to access or control Purchaser’s USG Tokens or the loss
                    of ETH in Purchaser’s account. In the event of such a software bug or weakness, there may be no
                    remedy and holders of USG Tokens are not guaranteed any remedy, refund or compensation.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 7.9 Access to Private Keys. USGold Tokens purchased by Purchaser may be held by Purchaser in
                    Purchaser’s digital wallet or vault, which requires a private key, or a combination of private keys,
                    for access. Accordingly, loss of requisite private key(s) associated with Purchaser’s digital wallet
                    or vault storing USG Tokens will result in loss of such USGold Tokens, access to Purchaser’s USG
                    Token balance and/or any initial balances in blockchains created by third parties. Moreover, any
                    third party that gains access to such private key(s), including by gaining access to login
                    credentials of a hosted wallet or vault service Purchaser uses, may be able to misappropriate
                    Purchaser’s USG Tokens. Company is not responsible for any such losses.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 7.10 Tax Consequences. The purchase and receipt of USG Tokens may have tax consequences for
                    Purchaser. Purchaser is solely responsible for Purchaser’s compliance with Purchaser’s tax
                    obligations.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 7.11 Reliance on Third-Parties. Even if completed, the Platform will rely, in whole or
                    partly, on third parties to adopt and implement it and to continue to develop, supply, and otherwise
                    support it. There is no assurance or guarantee that those third parties will complete their work,
                    properly carry out their obligations, or otherwise meet anyone’s needs, all of might have a material
                    adverse effect on the Platform.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 7.12. Failure to Map a Public Key to Purchaser’s Account. Failure of Purchaser to map a
                    public key to Purchaser’s account may result in third parties being unable to recognize Purchaser’s
                    USG Token balance on the Ethereum blockchain.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 7.13 Exchange & Counterparty Risks. If Purchaser sends ETH to the USG Distribution Contract
                    from an exchange or an account that Purchaser does not control, USGold Tokens will be allocated to
                    the account that has sent ETH; therefore, Purchaser may never receive or be able to recover
                    Purchaser’s USGold Tokens. Furthermore, if Purchaser chooses to maintain or hold USG Tokens through
                    a cryptocurrency exchange or other third party, Purchaser’s USG Tokens may be stolen or lost. In
                    addition, third parties may not recognize Purchaser’s claim to any derivative tokens if and when
                    launched by third parties according to the distribution rules set in the Platform. By using the USG
                    Distribution Contract and/or by purchasing USGold Tokens, Purchaser acknowledges and agrees that
                    Purchaser sends ETH to the USG Distribution Contract through an exchange account and/or holds Tokens
                    on a cryptocurrency exchange or with another third party at Purchaser’s own and sole risk.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 7.14 Uncertain Regulatory Framework. The regulatory status of cryptographic tokens, digital
                    assets and blockchain technology is unclear or unsettled in many jurisdictions. It is difficult to
                    predict how or whether governmental authorities will regulate such technologies. It is likewise
                    difficult to predict how or whether any governmental authority may make changes to existing laws,
                    regulations and/or rules that will affect cryptographic tokens, digital assets, blockchain
                    technology and its applications. Such changes could negatively impact USG Tokens in various ways,
                    including, for example, through a determination that USGold Tokens are regulated financial
                    instruments that require registration. Company may cease the distribution of USG Tokens, the
                    development of the Platform or cease operations in a jurisdiction in the event that governmental
                    actions make it unlawful or commercially undesirable to continue to do so.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 7.15 Additional Risks. The Tokens and the Platform are subject to such additional risks as
                    set forth on Schedule B.
                </OpenP>
            </AccordionItemBody>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemTitle>
                ARTICLE 8
            </AccordionItemTitle>
            <AccordionItemBody>
                <OpenP textAlign='left'>
                    <strong>LIMITATION OF LIABILITY; INDEMNIFICATION</strong><br/>
                    Section 8.1 Limitation of Liability. To the fullest extent permitted by applicable law, Purchaser
                    disclaims any right or cause of action against Company of any kind in any jurisdiction that would
                    give rise to any Damages whatsoever, on the part of Company. Company shall not be liable to
                    Purchaser for any type of damages, whether direct, indirect, incidental, special, punitive,
                    consequential or exemplary (including damages for lost profits, goodwill, use or data), even if and
                    notwithstanding the extent to which Company has been advised of the possibility of such damages.
                    Purchaser agrees not to seek any refund, compensation or reimbursement from a Company Party,
                    regardless of the reason, and regardless of whether the reason is identified in these Terms.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 8.2 Damages. In no circumstances will the aggregate joint liability of the Company Parties,
                    whether in contract, warrant, tort or other theory, for Damages to Purchaser under these Terms
                    exceed the amount received by Company from Purchaser.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 8.3 Force Majeure. Purchaser understands and agrees that Company shall not be liable and
                    disclaims all liability to Purchaser in connection with any force majeure event, including acts of
                    God, labor disputes or other industrial disturbances, electrical, telecommunications, hardware,
                    software or other utility failures, software or smart contract bugs or weaknesses, earthquakes,
                    storms, or other nature-related events, blockages, embargoes, riots, acts or orders of government,
                    acts of terrorism or war, technological change, changes in interest rates or other monetary
                    conditions, and, for the avoidance of doubt, changes to any blockchain-related protocol.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 8.4 Release. To the fullest extent permitted by applicable law, Purchaser releases Company
                    from responsibility, liability, claims, demands, and/or damages of every kind and nature, known and
                    unknown (including, but not limited to, claims of negligence), arising out of or related to disputes
                    between Purchaser and the acts or omissions of third parties.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 8.5 Indemnification. <br/>
                    (a) To the fullest extent permitted by applicable law, Purchaser will indemnify, defend and hold
                    harmless and reimburse Company from and against any and all actions, proceedings, claims, damages,
                    demands and actions (including without limitation fees and expenses of counsel), incurred by Company
                    arising from or relating to: (i) Purchaser’s purchase or use of USGold Tokens; (ii) Purchaser’s
                    responsibilities or obligations under these Terms; (iii) Purchaser’s breach of or violation of these
                    Terms; (iv) any inaccuracy in any representation or warranty of Purchaser; (v) Purchaser’s violation
                    of any rights of any other person or entity; and/or (vi) any act or omission of Purchaser that is
                    negligent, unlawful or constitutes willful misconduct. <br/>
                    (b) Company reserves the right to exercise sole control over the defense, at Purchaser’s expense, of
                    any claim subject to indemnification under this Section 8.5. This indemnity is in addition to, and
                    not in lieu of, any other indemnities set forth in a written agreement between Purchaser and
                    Company.
                </OpenP>

            </AccordionItemBody>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemTitle>
                ARTICLE 9
            </AccordionItemTitle>
            <AccordionItemBody>
                <OpenP textAlign='left'>
                    <strong>DISPUTE RESOLUTION</strong><br/>
                    Section 9.1 <em>Informal Dispute Resolution.</em> Purchaser and Company shall cooperate in good
                    faith to resolve any dispute, controversy or claim arising out of, relating to or in connection with
                    these Terms, including with respect to the formation, applicability, breach, termination, validity
                    or enforceability thereof (a “Dispute”). If the Parties are unable to resolve a Dispute within
                    ninety (90) days of notice of such Dispute being received by all Parties, such Dispute shall be
                    finally settled by Binding Arbitration as defined in Section 9.2 below.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 9.2 <em>Binding Arbitration.</em> Any Dispute not resolved within 90 days as set forth in
                    Section 9.1 shall be referred to and finally resolved by arbitration under the rules of the state of
                    Wyoming in effect at the time of the arbitration, except as they may be modified herein or by mutual
                    agreement of the Parties. The number of arbitrators shall be one who shall be selected by Company.
                    The seat, or legal place, of arbitration shall be Salt Lake City, Utah. The language to be used in
                    the arbitral proceedings shall be English. The governing law of the Agreement shall be as set forth
                    in Section 10.1 herein. The arbitration award shall be final and binding on the Parties (“<em>Binding
                    Arbitration</em>”). The Parties undertake to carry out any award without delay and waive their right
                    to any form of recourse insofar as such waiver can validly be made. Judgment upon the award may be
                    entered by any court having jurisdiction thereof or having jurisdiction over the relevant Party or
                    its assets. Company and Purchaser will each pay their respective attorneys’ fees and expenses.
                </OpenP>
                <OpenP textAlign='left'>
                    Section 9.3 <em>No Class Arbitrations, Class Actions or Representative Actions</em>. Any dispute
                    arising out of or related to these Terms is personal to Purchaser and Company and will not be
                    brought as a class arbitration, class action or any other type of representative proceeding. There
                    will be no class arbitration or arbitration in which an individual attempts to resolve a dispute as
                    a representative of another individual or group of individuals. Further, a dispute cannot be brought
                    as a class or other type of representative action, whether within or outside of arbitration, or on
                    behalf of any other individual or group of individuals.
                </OpenP>
            </AccordionItemBody>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemTitle>
                ARTICLE 10
            </AccordionItemTitle>
            <AccordionItemBody>
                <OpenP textAlign='left'>
                    <strong>MISCELLANEOUS</strong><br/>
                    Section 10.1 <em>Governing Law and Venue.</em> This Agreement shall be governed in all respects,
                    including as to validity, interpretation and effect, by the laws of Wyoming, without giving effect
                    to its principles or rules of conflict of laws, to the extent such principles or rules are not
                    mandatorily applicable by statute and would permit or require the application of the laws of another
                    jurisdiction.

                </OpenP>
                <OpenP textAlign='left'>
                    Section 10.2 <em>Assignment.</em> Purchaser shall not assign these Terms without the prior written
                    consent of Company. Any assignment or transfer in violation of this Section 10.2 will be void.
                    Company may assign these Terms to an affiliate. Subject to the foregoing, these Terms, and the
                    rights and obligations of the Parties hereunder, will be binding upon and inure to the benefit of
                    their respective successors, assigns, heirs, executors, administrators and legal representatives.

                </OpenP>
                <OpenP textAlign='left'>
                    Section 10.3 <em>Entire Agreement.</em> This Agreement, including the exhibits attached hereto and
                    the materials incorporated herein by reference, constitutes the entire agreement between the Parties
                    and supersedes all prior or contemporaneous agreements and understandings, both written and oral,
                    between the Parties with respect to the subject matter hereof, including, without limitation, any
                    public or other statements or presentations made by Company about the USGold Tokens or the Platform.

                </OpenP>
                <OpenP textAlign='left'>
                    Section 10.4 <em>Severability.</em> If any provision of these Terms is determined by a court of
                    competent jurisdiction to be invalid, inoperative or unenforceable for any reason, the provision
                    shall be modified to make it valid and, to the extent possible, effectuate the original intent of
                    the Parties as closely as possible in an acceptable manner in order that the transactions
                    contemplated hereby be consummated as originally contemplated to the fullest extent possible.

                </OpenP>
                <OpenP textAlign='left'>
                    Section 10.5 <em>Modification of Agreement.</em> Company may modify these Terms at any time by
                    posting a revised version on the Website, available at [Insert Web address]. The modified terms will
                    become effective upon posting. It is Purchaser’s responsibility to check the Website regularly for
                    modifications to these Terms. This Agreement was last modified on the date listed at the beginning
                    of these Terms.

                </OpenP>
                <OpenP textAlign='left'>
                    Section 10.6 <em>Termination of Agreement; Survival.</em> This Agreement will terminate upon the
                    completion of all sales in the USGOLD Token Distribution. Company reserves the right to terminate
                    these Terms, in its sole discretion, in the event that Purchaser breaches these Terms. Upon
                    termination of these Terms: (a) all of Purchaser’s rights under these Terms immediately terminate;
                    (b) Purchaser is not entitled to a refund of any amount paid; and (c) Articles 3, 4, 6, 7, 8, 9, and
                    10 will continue to apply in accordance with their terms.

                </OpenP>
                <OpenP textAlign='left'>
                    Section 10.7 <em>No Waivers.</em> The failure by Company to exercise or enforce any right or
                    provision of these Terms will not constitute a present or future waiver of such right or provision
                    nor limit Company’s right to enforce such right or provision at a later time. All waivers by Company
                    must be unequivocal and in writing to be effective.

                </OpenP>
                <OpenP textAlign='left'>
                    Section 10.8 <em>No Partnership; No Agency; No Third Party Beneficiaries.</em> Nothing in these
                    Terms and no action taken by the Parties shall constitute, or be deemed to constitute, a
                    partnership, association, joint venture or other co-operative entity between the Parties. Nothing in
                    these Terms and no action taken by the Parties pursuant to these Terms shall constitute, or be
                    deemed to constitute, either Party the agent of the other Party for any purpose. No Party has,
                    pursuant to these Terms, any authority or power to bind or to contract in the name of the other
                    Party. This Agreement does not create any third party beneficiary rights in any person.

                </OpenP>
                <OpenP textAlign='left'>
                    Section 10.9 <em>Electronic Communications.</em> Purchaser agrees and acknowledges that all
                    agreements, notices, disclosures and other communications that Company provides Purchaser pursuant
                    to these Terms or in connection with or related to Purchaser’s purchase of USGold Tokens, including
                    these Terms, may be provided by Company, in its sole discretion, to Purchaser, in electronic form.
                </OpenP>

            </AccordionItemBody>
        </AccordionItem>
    </Accordion>


);


class UserAgreement extends Component {
    // USGInUSD in state is master value for component - don't let the app modify it
    // state = { USGInUSD: 1627.50, ethereumPrice: 0.0, ethereum24hrChange: 0.0, USGInEthereum: 0.0 };

    render() {
        return (
            <Container fluid id={0}>
                <HeroHeader bgImage={TopBg}>
                    <Container>
                        <HhInnerContainer>
                            <HhTextContainer>
                                <HhH1>
                                    User Agreement
                                </HhH1>
                            </HhTextContainer>
                            <Fade>
                                <HhImageContainer bgImage={USGoldToken}/>
                            </Fade>
                        </HhInnerContainer>
                    </Container>
                </HeroHeader>

                <FullWidthDiv topColor='#f9f9f9' bottomColor='#f9f9f9' id={1}>
                    <Container>
                        <OpenH4>
                            This User Agreement is effective as December 1, 2020 for all users.
                        </OpenH4>
                        <OpenP>
                            By using the US Gold Currency, Inc. (“USGC”) website – https://www.USGold.io / BuyUSGold.com
                            (“Website”), and other services provided by USGC, and purchasing, receiving or otherwise
                            using the USGC monetary gold coin backed digital asset USGold token(s) (the “USG”) from
                            USGC, you agree to comply with the following terms and conditions, including, without
                            limitation, any terms available by hyperlink set forth herein (collectively, the “User
                            Agreement”). These terms apply to all use of the Website as well as the purchase, receipt,
                            use, transmittal and redemption of the USG whether made through the Website, directly from
                            USGC or upon one or more exchanges, or otherwise.
                        </OpenP>
                        <OpenP>
                            This User Agreement governs your purchase, maintenance, transmittal and redemption
                            transactions, including placement of USG orders on the website, through an exchange,
                            directly from USGC or otherwise, and any redemption from USGC as applicable.
                        </OpenP>
                    </Container>
                </FullWidthDiv>

                <FullWidthDiv topColor='#fff' bottomColor='#eee'>
                    <Container>
                        {/* <AccordionExampleNested /> */}
                        <Content/>
                        <OpenP textAlign='center'><a href={UserAgreementDownload}>Download Full User
                            Agreement</a></OpenP>


                    </Container>
                </FullWidthDiv>

                <Footer/>
            </Container>
        );
    }
}

const styles = {
    image50tall: {
        width: 'auto',
        height: '50px',
    },
    noPadOrMargin: {
        padding: 0,
        margin: 0,
    },
    goldBorder: {
        border: '1px',
        borderStyle: 'solid',
        borderColor: '#f8da1d',
    },
    pushedDown: {
        position: 'relative',
        top: '350px',
        // marginTop: '400px',
    },
    padTop: {
        paddingTop: '30px',
    },
    fullWidth: {
        width: '100%',
    },
}

export default UserAgreement;
