import React, {Component} from 'react';
import {Header, Container, Grid} from 'semantic-ui-react';
import TopBg from '../assets/images/usgold-header-bg-lg-v03.jpg';
import Footer from './partials/Footer';
import Wallet from '../assets/images/wallet.jpg';
import Img from './partials/Img';
import IDCoin from '../assets/images/IDCoin.png';
import {
    HeroHeader, HhInnerContainer, HhTextContainer,
    HhImageContainer, HhH1, HhH2,
    FullWidthDiv, FullWidthBgDiv,
    FlexRowContainer,
    OpenH3, OpenH4, OpenP, OpenPSmall, OpenSpanGreen, OpenSpanRed,
    BorderedDiv, BorderedDivHead, BorderedDivBody,
    HoverCenterDiv, HoverCenterDivWrap,
    DividerGrayGrad, VerticalSpacer,
    HrOrange,
    USGButton, Img2, HhH3, HhH4,
} from '../css/styledComponents';
import VaultBlue from "../assets/images/vault-color-med.png";
import USGoldToken from "../assets/images/usg-token.png";

import {USGInUSD} from "./partials/USGInUSD";
import Fade from "react-reveal/Fade";

class AMLKYC extends Component {

    render() {


        return (
            <Container fluid>
                <HeroHeader bgImage={TopBg}>
                    <Container>


                        <HhInnerContainer>
                            <HhTextContainer>
                                    <HhH1>AML / KYC</HhH1>

                                </HhTextContainer>

                            <Fade>
                                <HhImageContainer bgImage={USGoldToken}/>
                            </Fade>
                        </HhInnerContainer>
                    </Container>
                </HeroHeader>
                <FullWidthDiv topColor='#eaebee' bottomColor='#eaebee'>
                    <Container>
                        <div className='ui padded segment'>
                            <div>
                                <OpenH3>US Gold Currency offers our clients a seamless onboarding process for AML / KYC
                                    review with our working partner IDCoin network.</OpenH3>
                                <div><a href={'https://usgold.idcoin.net'}><Img2 spaced src={IDCoin} minHeight='100px'/></a></div>
                            </div>
                            <div>
                                <OpenH4>Please click on the url to begin the onboarding process.</OpenH4>

                                <OpenH4><a href={"https://usgold.idcoin.net"}>https://usgold.idcoin.net</a></OpenH4>
                                {/*<OpenP>(WE MAY NEED SOME DISCLAIMER INFORMATION AT THE BOTTOM OF THE PAGE)</OpenP>*/}


                            </div>
                        </div>

                    </Container>

                </FullWidthDiv>
                <Footer/>
            </Container>
        );
    }
}

export default AMLKYC;
