import React, { Component } from 'react';

import {
  HeroHeader, HhInnerContainer, HhTextContainer,
  HhImageContainer, HhH1, HhH2, HhH3,  HhH4, 
  FullWidthDiv, FullWidthBgDiv,
  FlexRowContainer,
  OpenH2, OpenH3, OpenH4, OpenP, OpenPSmall, OpenSpanGreen, OpenSpanRed,
  BorderedDiv, BorderedDivHead, BorderedDivBody,
  HoverCenterDiv, HoverCenterDivWrap,
  DividerGrayGrad, VerticalSpacer,
  HrOrange,
  USGButton,
} from '../../css/styledComponents';
import Img from './Img';

import Fade from 'react-reveal/Fade';
import USGoldToken from '../../assets/images/usg-token.png';
import EthGoldLogo from '../../assets/images/ethereum-gold.svg';

import { withRouter } from 'react-router-dom';


const $ = require('jquery');


class WalletTransactions extends Component {
  state = { ethereum_address: '' }


  setWeb3 = async ()=>{

      this.setState({
          ethereum_address: window.acct,
          usg_balance: window.bal,
          eth_balance: window.ethBal
        });
    }

    componentDidMount =  () => {
      if(window.acct === undefined || window.acct === null){
            window.addEventListener("web3Complete", this.setWeb3);
            window.addEventListener("setBalance", this.setBalance);
        }
        else {
            this.setWeb3();
        }
    }
setBalance = () => {
        this.setState({
          ethereum_address: window.acct,
          usg_balance: window.bal,
          eth_balance: window.ethBal
        });
  
  }

    componentWillUnmount(){
        
    }
   /* shouldComponentUpdate() {
        //return false;
    }
*/
    render() {
         const ethereum_address = this.state.ethereum_address;
        
        return (
            <div>
                <br/><br/><br/>
                <OpenH2>For a list of transactions visit: <a href={"https://etherscan.io/token/0x4000369acfa25c8fe5d17fe3312e30c332bef633?a="+ ethereum_address }>Etherscan</a></OpenH2>

            </div>);
    }

}

export default withRouter(WalletTransactions);