import React, { Component } from 'react'
import { Header, Container, Grid, Card, Button, Image } from 'semantic-ui-react'
// import TopBg from '../assets/images/us-gold-header-bg.jpg';
import TopBg from '../assets/images/usgold-header-bg-lg-v03.jpg'
import Footer from './partials/Footer'
//import SubmitForm from './partials/SubmitForm';
import Fade from 'react-reveal/Fade'
import WalletBalance from './partials/WalletBalance'
// import WalkingLiberty from '../assets/images/walking-liberty-coin.png';
//import USGoldToken from '../assets/images/usg-token.png';

//import LogoMedium from '../assets/images/logo-medium.png';
//import LogoShapeshift from '../assets/images/logo-shapeshift.png';
//import LogoCoinbase from '../assets/images/logo-coinbase.png';
//import LogoUSMint from '../assets/images/logo-usmint.png';
//import LogoBittrex from '../assets/images/logo-bittrex.png';
//import LogoBinance from '../assets/images/logo-binance.png';
//import LogoPoloniex from '../assets/images/logo-poloniex.png';
//import LogoSimex from '../assets/images/simex_logo.png';
//import LogoBlockfills from '../assets/images/logo-blockfills.png';
//import LogoDACX from '../assets/images/dacx.png';
import LogoHitBTC from '../assets/images/hit-btc-logo.png'
//import LogoCrix from '../assets/images/crix_main_logo.png';
//import LogoOKEX from '../assets/images/okex.jpg';
import LogoCoinZoom from '../assets/images/coinzoom-420x78.png'
import CoinPairUsgBtc from '../assets/images/usg-btc-button.png'
import CoinPairUsgEth from '../assets/images/usg-eth-button.png'
import CoinPairUsgUsd from '../assets/images/usg-usd-button.png'
import CoinPairUsgUsdt from '../assets/images/usg-usdt-button.png'

import {
	HeroHeader,
	HhInnerContainer,
	HhTextContainer,
	HhImageContainer,
	HhH1,
	HhH2,
	HhH3,
	HhH4,
	FullWidthDiv,
	FullWidthBgDiv,
	FlexRowContainer,
	OpenH2,
	OpenH3,
	OpenH4,
	OpenP,
	OpenPSmall,
	OpenSpanGreen,
	OpenSpanRed,
	BorderedDiv,
	BorderedDivHead,
	BorderedDivBody,
	HoverCenterDiv,
	HoverCenterDivWrap,
	DividerGrayGrad,
	VerticalSpacer,
	HrOrange,
	USGButton,
	Img2,
	Img3,
} from '../css/styledComponents'
import PurchaseForm from './partials/PurchaseForm'
import LogoEthplorer from '../assets/images/ethplorer.png'
import LogoMetamask from '../assets/images/logo-metamask.png'

class Contact extends Component {
	render() {
		return (
			<Container fluid>
				<HeroHeader bgImage={TopBg}>
					<Container>
						<WalletBalance title='Exchanges' />
						<div
							style={{
								position: 'absolute',
								bottom: '0px',
								paddingBottom: '20px',
							}}
						></div>
					</Container>
				</HeroHeader>

				<FullWidthDiv
					topColor='#eaebee'
					bottomColor='#eaebee'
					minHeight='800px'
					id={3}
				>
					<Container>
						<Grid stackable padded centered>
							<Grid.Row>
								<Grid.Column width={2}></Grid.Column>
								<Grid.Column width={12}>
									<OpenH3>
										Exchanges that offer trading of the USGold Token:
									</OpenH3>
								</Grid.Column>
								<Grid.Column width={2}></Grid.Column>
							</Grid.Row>

							<Grid.Row columns={2}>
								{/* <Grid.Column>
                  <Fade bottom delay={200}>
                    <Card fluid>
                  <Img2
                  src={LogoBlockfills}
                  width={'90%'}
                  maxWidth={'400px'}
                  />
                  <Card.Content>
                  <Card.Header>
                  <a href="https://www.blockfills.com/" target="_blank" rel="noopener noreferrer">Blockfills</a>
                  </Card.Header>
                  <Card.Meta>
                  </Card.Meta>
                  <Card.Description>
                  Blockfills is one of the first electronic, off-exchange digital
                  liquidity providers,
                  to provide institutional clients with access to deep liquidity and
                  real-time continuous pricing.
                  They offer high-touch support and deep market insight.
                  <br/>
                  <br/>
                  The Blockfills team has over 100 years of combined experience in
                  trading and technology,
                  drawing its team from some of the world’s largest banks and
                  brokerage firms.
                  By aggregating the world’s deepest exchange and off-exchange
                  liquidity hubs,
                  Blockfills’ clients save time sourcing liquidity and can obtain
                  larger fills at better prices.
                  <br/>
                  <br/>
                  <a href="https://www.blockfills.com/"
                  target="_blank"> blockfills.com</a>
                  </Card.Description>
                  </Card.Content>
                    </Card>
                  </Fade>
                </Grid.Column> */}
								<Grid.Column>
									<Fade bottom delay={200}>
										<Card fluid>
											<OpenH3>
												<br />
												United States
											</OpenH3>
											<Card.Content textAlign='center'>
												<br />
												<br />
												<Image
													src={LogoCoinZoom}
													size='medium'
													centered
													as='a'
													href='https://www.coinzoom.com/'
													target='_blank'
													rel='noopener noreferrer'
												/>
												{/* <Card.Header>
                            <a href="https://www.coinzoom.com/" target="_blank" rel="noopener noreferrer">CoinZoom</a>
                        </Card.Header> */}
												<Card.Meta>
													<a
														href='https://coinzoom.com'
														target='blank'
														rel='noopener noreferrer'
													>
														coinzoom.com
													</a>
												</Card.Meta>
												<br />
												<br />
											</Card.Content>
											<Card.Content textAlign='center'>
												<br />
												<Image
													src={CoinPairUsgBtc}
													centered
													size='tiny'
													as='a'
													href='https://trade.coinzoom.com/landing/USG_BTC'
													target='_blank'
													rel='noopener noreferrer'
												/>{' '}
												&nbsp;
												<Image
													src={CoinPairUsgUsd}
													centered
													size='tiny'
													as='a'
													href='https://trade.coinzoom.com/landing/USG_USD'
													target='_blank'
													rel='noopener noreferrer'
												/>{' '}
												&nbsp;
												<Image
													src={CoinPairUsgUsdt}
													centered
													size='tiny'
													as='a'
													href='https://trade.coinzoom.com/landing/USG_USDT'
													target='_blank'
													rel='noopener noreferrer'
												/>
												<br />
												<br />
												<br />
												{/* <p>CoinZoom is a U.S. based, regulated, institutional-grade digital currency trading platform
                          striving to bridge the gap between legacy FX, futures, stocks, and banking to make digital
                          assets available to both institutional and retail traders across the globe.</p>

                          <p>The CoinZoom team has extensive global exchange and trading experience and proven
                          capabilities of designing, building and operating a global financial services company with
                          a focus on safeguarding customer funds and digital currency positions. CoinZoom offers a
                          Visa debit card that can be used to convert cryptocurrency into fiat, instantly. You can
                          spend your US Gold token at any retail location that accepts chip, contactless, or PIN
                        payments from Visa.<a href="https://www.coinzoom.com" target="_blank"> www.coinzoom.com </a></p> */}
											</Card.Content>
										</Card>
									</Fade>
								</Grid.Column>

								{/* <Grid.Column>
                  <Fade bottom delay={400}>
                  <Card fluid>
                  <Img2 src={LogoDACX} width={'90%'} maxWidth={'400px'}/>
                  <Card.Content>
                  <Card.Header> <a href="https://dacx.io/"
                  target="_blank"
                  rel="noopener noreferrer">DACX</a></Card.Header>
                  <Card.Meta>

                  </Card.Meta>
                  <Card.Description>
                  <Card.Description style={{minHeight: 150}}>
                  DACX, a London based fintech/blockchain start-up located in the
                  famous Technology Accelerator known as LEVEL39. The DACX project
                  was
                  conceived during Q3 2018, with a vision to provide a next
                  generation
                  digital asset exchange which leverages machine learning and DLT
                  to
                  ensure a safe and compliant marketplace. Our goal is to provide
                  a
                  platform for both primary and secondary markets beyond
                  traditional
                  cryptocurrencies, by venturing out into the world of tokenized
                  securities and commodities. Therefore, our product offerings
                  cater
                  solutions to the retail sector, professional and institutional
                  grade
                  clientele.
                  <br/>
                  <br/>
                  <a href="https://dacx.io/"
                  target="_blank">DACX.io</a>
                  </Card.Description>
                  </Card.Description>
                  </Card.Content>
                  </Card>
                  </Fade>
                </Grid.Column> */}

								{/*<Image
                          src={LogoHitBTC}
                          size='medium'
                          centered
                          as='a'
                          href='https://hitbtc.com/'
                          target="_blank"
                          rel="noopener noreferrer"
                        />

                        <Card.Meta>
                          <a href="https://hitbtc.com/"
                            target="_blank"
                          rel="noopener noreferrer">
                            hitbtc.com
                          </a>
                        </Card.Meta>
                      </Card.Content>

                      <Card.Content textAlign='center'>
                        <br />
                        <Image
                          src={CoinPairUsgBtc}
                          centered
                          size='tiny'
                          as='a'
                          href='https://hitbtc.com/usg-to-btc'
                          target="_blank"
                          rel="noopener noreferrer"
                        /> &nbsp;
                        <Image
                          src={CoinPairUsgEth}
                          centered
                          size='tiny'
                          as='a'
                          href='https://hitbtc.com/usg-to-eth'
                          target="_blank"
                          rel="noopener noreferrer"
                        /> &nbsp;
                        <Image
                          src={CoinPairUsgUsdt}
                          centered
                          size='tiny'
                          as='a'
                          href='https://hitbtc.com/usg-to-usdt'
                          target="_blank"
                          rel="noopener noreferrer"
                        />

                        <br /><br />
                        <br />



                      </Card.Content>


                    </Card>
                  </Fade>
                </Grid.Column>*/}
							</Grid.Row>

							{/*  <Grid.Row columns='equal'>*/}

							{/*  <Grid.Column>*/}
							{/*    <Fade bottom*/}
							{/*          delay={200}>*/}
							{/*      <Card fluid>*/}
							{/*        <Img2 src={LogoCoinZoom}*/}
							{/*              width={'90%'}*/}
							{/*              maxWidth={'400px'}/>*/}
							{/*        <Card.Content>*/}
							{/*          <Card.Header><a href="https://coinzoom.com"*/}
							{/*                          target="_blank"*/}
							{/*                          rel="noopener noreferrer">CoinZoom</a></Card.Header>*/}
							{/*          <Card.Meta>*/}
							{/*          </Card.Meta>*/}
							{/*          <Card.Description>*/}
							{/*            A platform for everyone from high-frequency traders to casual investors. Our Desktop, Apple, and*/}
							{/*            Android platforms have a solution for everyone. Plus, CoinZoom offers trading in over 40*/}
							{/*            cryptocurrencies and over 110 market trading pairs.*/}
							{/*            <a href="https://coinzoom.com"*/}
							{/*               target="_blank"> coinzoom.com</a>*/}
							{/*          </Card.Description>*/}
							{/*        </Card.Content>*/}
							{/*      </Card>*/}
							{/*    </Fade>*/}
							{/*  </Grid.Column><Grid.Column>*/}
							{/*  <Fade bottom*/}
							{/*        delay={200}>*/}
							{/*    <Card fluid>*/}
							{/*      <Img2 src={LogoOKEX}*/}
							{/*            width={'90%'}*/}
							{/*            maxWidth={'400px'}/>*/}
							{/*      <Card.Content>*/}
							{/*        <Card.Header><a href="https://www.okex.com/en"*/}
							{/*                        target="_blank"*/}
							{/*                        rel="noopener noreferrer">OKEX</a></Card.Header>*/}
							{/*        <Card.Meta>*/}
							{/*        </Card.Meta>*/}
							{/*        <Card.Description>*/}
							{/*          OKEX is a world's leading cryptocurrency exchange with high liquidity and cold storage*/}
							{/*          technologies which offer BTC, LTC, ETH, BCH, Qtum, Ripple, NEO and EOS trading.*/}
							{/*          <a href="https://www.okex.com/en"*/}
							{/*             target="_blank"> okex.com</a>*/}
							{/*        </Card.Description>*/}
							{/*      </Card.Content>*/}
							{/*    </Card>*/}
							{/*  </Fade>*/}
							{/*</Grid.Column>*/}

							{/*  <Grid.Column>*/}
							{/*    <Fade bottom*/}
							{/*          delay={400}>*/}
							{/*      <Card fluid>*/}
							{/*          <Img2 src={LogoDACX} width={'90%'} maxWidth={'400px'}/>*/}
							{/*          <Card.Content>*/}
							{/*              <Card.Header> <a href="https://dacx.io/"*/}
							{/*                               target="_blank"*/}
							{/*                               rel="noopener noreferrer">Kraken</a></Card.Header>*/}
							{/*              <Card.Meta>*/}

							{/*              </Card.Meta>*/}
							{/*              <Card.Description>*/}
							{/*                  <Card.Description style={{minHeight: 150}}>*/}
							{/*                      DACX, a London based fintech/blockchain start-up located in the*/}
							{/*                      famous Technology Accelerator known as LEVEL39. The DACX project*/}
							{/*                      was*/}
							{/*                      conceived during Q3 2018, with a vision to provide a next*/}
							{/*                      generation*/}
							{/*                      digital asset exchange which leverages machine learning and DLT*/}
							{/*                      to*/}
							{/*                      ensure a safe and compliant marketplace. Our goal is to provide*/}
							{/*                      a*/}
							{/*                      platform for both primary and secondary markets beyond*/}
							{/*                      traditional*/}
							{/*                      cryptocurrencies, by venturing out into the world of tokenized*/}
							{/*                      securities and commodities. Therefore, our product offerings*/}
							{/*                      cater*/}
							{/*                      solutions to the retail sector, professional and institutional*/}
							{/*                      grade*/}
							{/*                      clientele.*/}
							{/*                      <br/>*/}
							{/*                      <br/>*/}
							{/*                      <a href="https://dacx.io/"*/}
							{/*                         target="_blank">DACX.io</a>*/}
							{/*                  </Card.Description>*/}
							{/*              </Card.Description>*/}
							{/*          </Card.Content>*/}
							{/*      </Card>*/}
							{/*    </Fade>*/}
							{/*  </Grid.Column>*/}
							{/*  <Grid.Column>*/}
							{/*    <Fade bottom*/}
							{/*          delay={400}>*/}
							{/*      <Card fluid>*/}
							{/*          <Img2 src={LogoHitBTC} width={'90%'} maxWidth={'400px'}/>*/}
							{/*          <Card.Content>*/}
							{/*              <Card.Header> <a href="https://hitbtc.com/"*/}
							{/*                               target="_blank"*/}
							{/*                               rel="noopener noreferrer">BitMART</a></Card.Header>*/}
							{/*              <Card.Meta>*/}
							{/*                  <a href="https://hitbtc.com/" target="_blank"*/}
							{/*                     rel="noopener noreferrer">*/}
							{/*                      Bitcoin Exchange: Bitcoin, Ethereum, EOS, Zcash / HitBTC*/}
							{/*                  </a>*/}
							{/*              </Card.Meta>*/}
							{/*              <Card.Description>*/}
							{/*                  <Card.Description style={{minHeight: 150}}>*/}
							{/*                      The most advanced cryptocurrency exchange to buy and sell*/}
							{/*                      Bitcoin, Ethereum, Litecoin, EOS, ZCash, Ripple, Ardor*/}
							{/*                      <br/>*/}
							{/*                      <br/>*/}
							{/*                      <a href="https://hitbtc.com/"*/}
							{/*                         target="_blank"> HitBTC.com</a>*/}
							{/*                  </Card.Description>*/}
							{/*              </Card.Description>*/}
							{/*          </Card.Content>*/}
							{/*      </Card>*/}
							{/*    </Fade>*/}
							{/*  </Grid.Column>*/}

							{/*</Grid.Row>*/}
						</Grid>
						{/* <Grid padded stackable>
              <Grid.Row columns='equal'>
                <Grid.Column textAlign='center'>
              <Fade bottom>
              <BorderedDiv>
              <BorderedDivBody>
              <Img src={LogoShapeshift} width={200} height={150} mode='fill' />
              </BorderedDivBody>
              <BorderedDivHead>
              Crypto Conversion
              </BorderedDivHead>
              <BorderedDivBody>
              Shapeshift is the go-to site when you want to convert your other forms of cryptocurrency to Ethereum. <br /><br />

              It's fast, it's easy, the rates are reasonable, and they support an impressive slew of cryptocurrencies.
              // </BorderedDivBody>
              </BorderedDiv>
              </Fade>
                </Grid.Column>
                <Grid.Column textAlign='center'>
              <Fade bottom delay={200}>
              <BorderedDiv>
              <BorderedDivBody>
              <Img src={LogoCoinbase} width={200} height={150} mode='fill' />
              </BorderedDivBody>
              <BorderedDivHead>
              Exchange
              </BorderedDivHead>
              <BorderedDivBody>
              A solid exchange for Bitcoin, Ethereum, and Litecoin. Always on the right side of U.S. regulations, and their cryptocurrencies are FDIC insured.
              </BorderedDivBody>
              </BorderedDiv>
              </Fade>
                </Grid.Column>
                <Grid.Column textAlign='center'>
              <Fade bottom delay={400}>
              <BorderedDiv>
              <BorderedDivBody>
              <Img src={IconPerfectExit} width={120} height={77} mode='fill' />
              </BorderedDivBody>
              <BorderedDivHead>
              100% Perfect Exit
              </BorderedDivHead>
              <BorderedDivBody>
              USG tokens are always redeemable for physical 1 oz American Gold Eagle coins:<br /><br />
              Plan your hedge play or exit from cryptocurrencies that aren't asset-backed.
              </BorderedDivBody>
              </BorderedDiv>
              </Fade>
                </Grid.Column>
              </Grid.Row>
            </Grid> */}
					</Container>
				</FullWidthDiv>

				<Footer />
			</Container>
		)
	}
}

export default Contact
