import React, { Component } from 'react'
import { Header, Container, Grid } from 'semantic-ui-react'
import TopBg from '../assets/images/usgold-header-bg-lg-v03.jpg'
import Footer from './partials/Footer'
import RedeemForm from './partials/RedeemForm'
import WalletBalance from './partials/WalletBalance'
import Fade from 'react-reveal/Fade'
// import WalkingLiberty from '../assets/images/walking-liberty-coin.png';
import USGoldToken from '../assets/images/usg-token.png'
import {
	HeroHeader,
	HhInnerContainer,
	HhTextContainer,
	HhImageContainer,
	HhH1,
	HhH2,
	FullWidthDiv,
	FullWidthBgDiv,
	FlexRowContainer,
	OpenH3,
	OpenH4,
	OpenP,
	OpenPSmall,
	OpenSpanGreen,
	OpenSpanRed,
	BorderedDiv,
	BorderedDivHead,
	BorderedDivBody,
	HoverCenterDiv,
	HoverCenterDivWrap,
	DividerGrayGrad,
	VerticalSpacer,
	HrOrange,
	USGButton,
} from '../css/styledComponents'

class Redeem extends Component {
	render() {
		return (
			<Container fluid>
				<HeroHeader bgImage={TopBg}>
					<Container>
						<WalletBalance title='Redeem USG' />
					</Container>
				</HeroHeader>
				<FullWidthDiv topColor='#eaebee' bottomColor='#eaebee'>
					<Container>
						<OpenH3>US Gold Currency Token Redemption</OpenH3>
						<div className='ui segment padded'>
							<OpenP></OpenP>

							{/*<OpenP>
								Please click this link to go to the USG Token Trustee page at{' '}
								<a href={'https://www.redeemusg.com'}>RedeemUSG.com</a> Thank
								you.
							</OpenP>*/}
							<OpenP>
								To redeem your USG please email us at{' '}
								<a href='mailto:RedeemUSG@gmail.com'>RedeemUSG@gmail.com</a> or{' '}
								<a href='mailto:Legal@buyusgold.com'>Legal@buyusgold.com</a>{' '}
								providing us with your contact information and the quantity of
								USG you wish to redeem, and we will be in contact with you to
								initiate the process.
							</OpenP>
						</div>
						{/*<div class='ui segment padded'>*/}
						{/*    <OpenP>*/}

						{/*    </OpenP>*/}
						{/*    <OpenP>*/}
						{/*        If you would like to redeem your USGold Tokens for the American Eagle one-ounce gold*/}
						{/*        coins,*/}
						{/*        simply fill in your information below and submit.*/}
						{/*    </OpenP>*/}
						{/*    <OpenP>*/}
						{/*        Once you submit your information our Support Staff will contact you and provide the*/}
						{/*        instructions and steps on how to complete your redemptions. The Trustee in charge of the*/}
						{/*        vaulted gold coins will be subsequently notified and prepare and arrange the shipment of*/}
						{/*        your gold coins.*/}
						{/*    </OpenP>*/}
						{/*    <OpenP>*/}
						{/*        If you have further questions please contact our support team by email at: <a*/}
						{/*        href='mailto:support@buyusgold.com'>support@buyusgold.com</a><br/>*/}
						{/*        Note that there are countries from which you are not permitted to redeem.*/}
						{/*    </OpenP>*/}
						{/*    <OpenP>*/}
						{/*        If you live outside the USA and want to redeem your USG Tokens, please take a moment to*/}
						{/*        determine if your country is currently on a sanctions list –– whereby you may not be*/}
						{/*        able to receive a shipment of your gold-coins.*/}
						{/*    </OpenP>*/}
						{/*    <OpenP>*/}
						{/*        To see if your location is on the list prohibiting shipments of gold or gold coins from*/}
						{/*        the United States of America please click the following link:*/}
						{/*    </OpenP>*/}
						{/*    <OpenP><a href='https://www.treasury.gov/resource-center/sanctions/Programs/Pages/Programs.aspx'>https://www.treasury.gov/resource-center/sanctions/Programs/Pages/Programs.aspx</a></OpenP>*/}
						{/*</div>*/}
					</Container>
					{/*<Container>*/}
					{/*    <RedeemForm/>*/}
					{/*</Container>*/}
					{/* <SubmitForm /> */}
				</FullWidthDiv>

				<Footer />
			</Container>
		)
	}
}

export default Redeem
