import React, { Component } from 'react'

import {
	HeroHeader,
	HhInnerContainer,
	HhTextContainer,
	HhImageContainer,
	HhH1,
	HhH2,
	HhH3,
	HhH4,
	FullWidthDiv,
	FullWidthBgDiv,
	FlexRowContainer,
	OpenH3,
	OpenH4,
	OpenP,
	OpenPSmall,
	OpenSpanGreen,
	OpenSpanRed,
	BorderedDiv,
	BorderedDivHead,
	BorderedDivBody,
	HoverCenterDiv,
	HoverCenterDivWrap,
	DividerGrayGrad,
	VerticalSpacer,
	HrOrange,
	USGButton,
} from '../../css/styledComponents'
import Img from './Img'
import WalletControls from './WalletControls'
import Fade from 'react-reveal/Fade'
import USGoldToken from '../../assets/images/usg-token.png'
import EthGoldLogo from '../../assets/images/ethereum-gold.svg'

import { withRouter } from 'react-router-dom'
import { Container, Button } from 'semantic-ui-react'
let $ = require('jquery')
let jQuery = $

let request = require('request')
async function getJSON(url) {
	return new Promise((res, err) => {
		request(url, function (error, response, body) {
			if (!error && response.statusCode === 200) {
				let importedJSON = JSON.parse(body)
				res(importedJSON)
			} else {
				err(error)
			}
		})
	})
}

class WalletBalance extends Component {
	state = {
		ethereum_address: '',
		usg_balance: '...',
		eth_balance: '...',
		has_account: false,
		USGInUSD: 0.0,
	}

	componentDidMount = () => {
		let usgPriceUrl =
			window.location.protocol + '//' + window.location.host + '/api/usgprice'
		getJSON(usgPriceUrl).then((usgPriceJson) => {
			this.setState({ USGInUSD: usgPriceJson.USG })
			console.log('usgPriceJson: ')
			console.log(usgPriceJson.USG)
		})
		//this.setState({ USGInUSD: 3070.0 })

		if (window.acct === undefined || window.acct === null) {
			window.addEventListener('web3Complete', this.setWeb3)
			window.addEventListener('setBalance', this.setBalance)
		} else {
			this.setWeb3()
		}
	}
	setWeb3 = () => {
		let hasAccount = false
		if (localStorage.getItem('hasAccount') !== null) {
			hasAccount = true
		}

		if (!window.needsMetamask) {
			if (!window.needsAccount) {
				this.setState({
					ethereum_address: window.acct,
					usg_balance: window.bal,
					eth_balance: window.ethBal,
					has_account: hasAccount,
				})
			} else {
			}
		} else {
		}
	}

	setBalance = () => {
		this.setState({
			usg_balance: window.bal,
			eth_balance: window.ethBal,
		})
	}
	render() {
		const { ethereum_address, usg_balance, eth_balance, has_account } =
			this.state

		return (
			<HhInnerContainer>
				{ethereum_address === '' ? (
					<HhTextContainer>
						<HhH1>{this.props.title}</HhH1>
					</HhTextContainer>
				) : (
					<HhTextContainer>
						<HhH1>{this.props.title}</HhH1>

						<HhH2>Your wallet balance</HhH2>
						<table>
							<tbody>
								<tr>
									<td rowSpan={2} width={60}>
										<Img src={USGoldToken} width={60} height={60} />
									</td>
									<td>
										<HhH3>{usg_balance} USG</HhH3>
									</td>
								</tr>
								<tr>
									<td>
										<HhH3>
											${(usg_balance * this.state.USGInUSD).toFixed(2)} USD*
											{/*${usg_balance * 3070} USD**/}
										</HhH3>
									</td>
								</tr>
							</tbody>
						</table>

						<HhH2>Your wallet address</HhH2>
						<HhH4>{ethereum_address}</HhH4>
					</HhTextContainer>
				)}
				<Fade>
					<HhImageContainer bgImage={USGoldToken} />
				</Fade>
			</HhInnerContainer>
		)
	}
}

export default withRouter(WalletBalance)
